const ADMIN_DECLARATION_FORMS = 'api/admin/declarationForm'
const CONSUMER_ASSESMENT_FORM = '/api/user/assesmentform'
const CONSUMER_ASSESMENT_RESPONSE = '/api/user/assesmentresponse'
const CONSUMER_ENTITY = '/api/user/entity'
const CONSUMER_OTP = '/api/user/otp'
const USER = '/api/user/auth'
const USER_ACTIVITY = '/api/user/activity'

module.exports = {
    ADMIN_DECLARATION_FORMS,
    CONSUMER_ASSESMENT_FORM,
    CONSUMER_ASSESMENT_RESPONSE,
    USER,
    CONSUMER_ENTITY,
    CONSUMER_OTP,
    USER_ACTIVITY
}