import { GET_ASSESMENT_FORMS_ERROR, GET_CONSUMER_ASSESMENT_FORM, ASSESSMENT_SUBMITTED_SUCCESS, ASSESSMENT_SUBMITTED_ERROR, RESET_ALL_ERROR, GET_ASSESMENT_RESPONSE_SUCCESS, GET_ASSESMENT_RESPONSE_ERROR, ASSESSMENT_DRAFT_SUCCESS} from '../actions/actions';

const initialState = {
        consumerAssesmentForm:null,
        assessmentSubmitted:false,
        getAssessmentError: null,
        postAssessmentError: null,
        assessmentResponse: null,
        isAssessmentDraftSaved: false
}
const formReducer = (state, action) => {
    switch(action.type){
        case GET_ASSESMENT_FORMS_ERROR:
                return Object.assign({}, state, { getAssessmentError: action.payload});
        case GET_CONSUMER_ASSESMENT_FORM:
                return Object.assign({}, state, { consumerAssesmentForm: action.payload, getAssessmentError: null} )
        case ASSESSMENT_SUBMITTED_SUCCESS:
                return Object.assign({}, state, {assessmentSubmitted: true,});
        case ASSESSMENT_SUBMITTED_ERROR:
                return Object.assign({}, state, {postAssessmentError: action.payload, assessmentSubmitted: false});
        case GET_ASSESMENT_RESPONSE_SUCCESS:
                return Object.assign({}, { assessmentResponse: action.payload })
        case ASSESSMENT_DRAFT_SUCCESS:
                return Object.assign({}, { isAssessmentDraftSaved: true })
        case RESET_ALL_ERROR:
                return Object.assign({}, state, { getAssessmentError: null, postAssessmentError: null, isAssessmentDraftSaved: false});
        default:
                if(state)
                        return state;
                else
                        return initialState;
    }
}
export default formReducer;