import { USER_LOGIN_SUCCESS, USER_LOGIN_ERROR, ASSESSMENT_FORM_INFO_SUCCESS, GET_ASSESSMENT_FORM_ERROR, RESET_LOGIN_ERROR, VERIFY_OTP_SUCCESS, VERIFY_OTP_ERROR, SEND_OTP_ERROR, SEND_OTP_SUCCESS, VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_ERROR} from '../actions/actions';

const initialState = {
        isAuthenticated: false,
        assessmentFormInfo: null,
        loginError: null,
        assessmentFormError: null,
        assessmentFormId: null,
        isOtpVerified: false,
        otpError: null,
        otpPayload: null,
        isUserLoggedIn: false,
        tokenError: null,
        verifyOtpError: null,
        assessmentId: null
}
const loginReducer = (state, action) => {
    switch(action.type){
        case USER_LOGIN_SUCCESS:
                return Object.assign({}, state, { assessmentFormId: action.payload.assesmentFormId, isAuthenticated: true, loginError:null});
        case USER_LOGIN_ERROR:
                return Object.assign({}, state, { loginError: action.payload} )
        case ASSESSMENT_FORM_INFO_SUCCESS:
                return Object.assign({}, state, {assessmentFormInfo: action.payload})
        case GET_ASSESSMENT_FORM_ERROR:
                return Object.assign({}, state, {assessmentFormError : action.payload})
        case RESET_LOGIN_ERROR:
                return Object.assign({}, state, { loginError:  null, assessmentFormError: null, otpError:null, tokenError:null, verifyOtpError: null})
        case VERIFY_OTP_SUCCESS:
                return Object.assign({}, state, { isOtpVerified: true, verifyOtpError: null, assessmentId: action.payload? action.payload.id : undefined})
        case VERIFY_OTP_ERROR:
                return Object.assign({}, state, { isOtpVerified: false, verifyOtpError: action.payload })
        case SEND_OTP_SUCCESS:
                return Object.assign({}, state, { otpPayload: action.payload, otpError: null,})
        case SEND_OTP_ERROR:
                return Object.assign({}, state, { otpError: action.payload })
        case VERIFY_TOKEN_SUCCESS:
                return Object.assign({}, state, { isUserLoggedIn: true, tokenError: null})
        case VERIFY_TOKEN_ERROR:
                return Object.assign({}, state, { isUserLoggedIn: false, tokenError: action.payload })
        default:
                if(state)
                        return state;
                else
                        return initialState
    }
}

export default loginReducer;   