import React, { Component } from 'react';
import {Button, Col, Container,Form, FormGroup, Row} from 'reactstrap';
import CheckOutImage from '../../assets/checkOutImage.png';
import CheckInImage from '../../assets/CheckInImage.png';
import './CheckInButton.css';


class CheckInButton extends Component {

    constructor(props){
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <div className="app flex-row align-items-center mt-n5">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs="12" sm={10} lg={5} md={6}>
                            <Form>
                                
                                <FormGroup className="justify-content-center" row style={{ marginTop: '55px' }}>
                                    <Row className="justify-content-center">
                                        <Col lg="6" xs="12" sm={10}>
                                            <img src={CheckInImage} style={{width: '100%'}} alt='Background' />
                                            
                                        </Col>
                                    </Row>
                                    <Button className='checkInButton' type='submit' onClick={this.props.checkInClick}>
                                        {/* <i className='mr-3 fa fa-sign-in' /> */}
                                        Check-In</Button>
                                </FormGroup>
                                 <FormGroup className="justify-content-center" row style={{ marginTop: '55px' }}>
                                 <Row className="justify-content-center">
                                        <Col lg="6" xs="12" sm={10}>
                                            <img src={CheckOutImage} style={{width: '100%'}} alt='Background' />
                                            
                                        </Col>
                                    </Row>
                                    <Button className='checkInButton' type='submit' onClick={this.props.checkOutClick}>
                                        {/* <i className='mr-3 fa fa-sign-out' /> */}
                                        Check-Out</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default CheckInButton