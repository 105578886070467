import React, { Component } from 'react';
import * as Survey from "survey-react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row, Card, CardHeader, CardBody, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { submitAssesmentResponse, getAssesmentFormForConsumer, resetError, draftSaveSuccess } from '../../actions/consumerAssesmentFormAction';
import {assessment, fileUpload} from '../../services';
import imageCompression from 'browser-image-compression';
import  { ScaleLoader, ClipLoader} from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import Toastmessage from '../../components/ToastMessage';
import storageService from '../../services/StorageService';

Survey.StylesManager.applyTheme("modern");
Survey.surveyLocalization.locales[Survey.surveyLocalization.defaultLocale].cleanCaption = "Remove All";
const styles = {
    buttonStyle : { 
        backgroundColor: '#1AB394', 
        border: 'none', 
        borderRadius: '1.214em',
        width: '140px',
        fontFamily: 'inherit', 
        fontSize: '0.875em',
        fontWeight: 'bold'
    }
}

class SelfAssesmentForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            jsonForm: null,
            result: null,
            mode:'',
            surveyResponse: null,
            surveyModel: null,
            files: [],
            currentPage: 0,
            isLoading: false,
            isFileUploading: false,
            isSaving:false,
            showPrompt: false,
            showErrorAlert: false,
            showSaveAsDraftModel:false   
        }
        this.compressionOption = {
            maxSizeMB: 2,
            maxWidthOrHeight:1024
          }
        this.onComplete = this.onComplete.bind(this);
    }

    componentDidMount() {
        Survey.FunctionFactory.Instance.register('matrixColumnHasValue', this.matrixColumnHasValue)
        let { params } = this.props.match;
        if(params && params.assesmentId){
            this.setState({ isLoading: true});
            this.props.getAssesmentFormForConsumer(params.assesmentId, this.props.history);
        } 
    }

    componentDidUpdate(){
        
        if(this.props.assesmentForm && this.state.isLoading){
            this.initializeSurveyResponse()
            this.setState({ isLoading: false, jsonForm: this.props.assesmentForm.jsonForm, showPrompt: true});

        }
        if(this.props.getAssessmentError && this.state.isLoading){
            this.setState({ isLoading: false});
            this.props.history.push('/error')
        }
        if(this.props.assessmentSubmitted && this.state.isSaving){
            this.setState({isSaving: false});
            this.props.history.replace('/user/assessment/success');
        }
        if(this.props.postAssessmentError && this.state.isSaving){
            this.setState({isSaving: false });
            this.renderErrorAlert()
            this.initializeSurveyResponse()
        }
        if(this.state.showPrompt){
            window.onbeforeunload = () => true
        }
        
    }

    componentWillUnmount(){
        //localStorage.clear()
        window.onbeforeunload = null;
    }

    renderForm(json, mode) {
        return(
            <div className="surveyjs">
               <Survey.Survey
            model={this.state.surveyModel}
            onComplete={this.onComplete}
            showCompletedPage={false}
            onUploadFiles={this.onFileUpload}
            onClearFiles={this.removeFiles}
            onCurrentPageChanged={this.onCurrentPageChanged}
            //onDownloadFile={this.onFileDownload}
            //onValueChanged={this.valueChangeEvent}
          />
          </div>
            //</div>
            
        )
    }

    renderErrorAlert(){
        if(this.props.postAssessmentError && this.props.postAssessmentError.error){
            toast.error(<Toastmessage type='error' message='Error while submitting form' description={this.props.postAssessmentError.error} ></Toastmessage>, { autoClose: false, closeOnClick:false, onClose: this.props.resetError})
        }else {
            toast.error(<Toastmessage type='error' message='Error while submitting form' ></Toastmessage>, {onClose: this.props.resetError})
        }
       
    }

    renderSaveAsDraftModel = () => {
        const email = storageService.getItem('_username')
        return (
            <Modal  isOpen={this.state.showSaveAsDraftModel}>
                <ModalHeader toggle={() => this.setState({ showSaveAsDraftModel: !this.state.showSaveAsDraftModel})}>Save As Draft</ModalHeader>
                <ModalBody>Do you want to save your survey response and resume later? Click on ‘Save and Close’ and we will email the access link for this survey to <b>{email}</b></ModalBody>
                <ModalFooter>
                    <Button color='link' onClick={(event) => this.onSaveConfirmClick(event, false)}>Save & Continue</Button>
                    <Button style={styles.buttonStyle} onClick={(event) => this.onSaveConfirmClick(event, true)}>Save & Close</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render(){
        
        let loaderActive = this.state.isFileUploading || this.state.isSaving
        return(
            <div className="container">
                {this.state.isLoading && this.renderLoader()}
                <LoadingOverlay 
                    active={loaderActive} 
                    spinner={this.state.isSaving ? this.renderLoader() : <ClipLoader size={50} color='#1AB394'/>}
                    styles={{
                        overlay: (base) => ({
                          ...base,
                          background: 'none'
                        })
                      }}
                >
                    <Card>
                        {this.props.assesmentForm && this.props.assesmentForm.isContactRequired &&
                        <CardHeader>
                            <Row>
                                <Col lg={9}/>
                                <Col lg={3}>
                                    <Button className='ml-5' style={styles.buttonStyle} onClick={this.saveAsDraftClick}>Save As Draft</Button>    
                                </Col>
                            </Row>
                        </CardHeader>}
                        <CardBody>
                            {!this.state.isLoading && this.state.jsonForm && this.renderForm(this.state.jsonForm, this.state.mode)}
                        </CardBody>
                    </Card>
                    {this.state.showSaveAsDraftModel && this.renderSaveAsDraftModel()}
                </LoadingOverlay>
               
           </div>
        )
    }
    
    renderLoader(){
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {/* <Loader  type={loader} color="#1AB394" height={100} width={100} /> */}
                        <ScaleLoader color='#1AB394' height={100} width={10} radius={4} margin={4}/>
                    </Row>
                </Container>
            </div>
           
        )
    }

    onComplete(result, options) {
        //options.showDataSaving('Please wait while we save your answers');
        this.setState({isSaving: true, showPrompt: false, surveyResponse: result.data});
        let assesmentResponse = {
            assesmentFormId: this.props.assesmentForm.assesmentFormId,
            response: result.data,
            files: this.state.files
        };

        this.props.submitAssesmentResponse(assesmentResponse, this.props.history);
    }

    onFileUpload = async (sender, options) => {
        let formData = new FormData()
        this.setState({isFileUploading: true});
        formData.append('questionName', options.name);
        try {
            for(const file of options.files){
                if(file.type.includes('image')){
                    let compressedblob = await imageCompression(file, this.compressionOption);
                    let compFile = compressedblob
                    compFile.name = file.name;
                    compFile.lastModified = new Date();
                    formData.append('file', compFile, file.name);
                }else {
                    formData.append('file', file);
                }
            }
            
            let response = await fileUpload.uploadFile(formData);
            console.log('Response from Service is ', response);
            this.setState({isFileUploading: false});
            if(response.statusCode === 201){
                const previousFiles = this.state.files
                const filesFromResponse = response.data
                if(previousFiles.length === 0)
                    this.setState({files: [...filesFromResponse]});
                else {
                    this.setState({files: [...previousFiles, ...filesFromResponse]})
                }
                options.accept = true;
                toast.success(<Toastmessage type='success' message='Attachment uploaded'  ></Toastmessage>, { containerId:'attachmentSuccess', autoClose:2000})
                options.callback("success", options.files.map(file => {
                        return {
                            file: file,
                            content: (response.data || []).find(item => item.originalName === file.name).filename
                            }
                }))
            }
            
        } catch (error) {
            console.log('Error is ', error)
            this.setState({isFileUploading: false});
            options.accept = false;
            options.callback("success", [])
            if(error.statusCode === 400){
                let err = error.error || {}
                let message = err.message === 'Unexpected field' ? 'Attachment limit reached' : err.message
                let description = err.message === 'Unexpected field' ? 'Only 10 attachments are allowed in one go.' : undefined
                toast.error(<Toastmessage type='error' message={message || 'Malicious Content'} description={description}  />,  { containerId: 'fileLimitError', closeOnClick:false, autoClose: description ? false : true})
            } else if(error.statusCode === 413){
                toast.error(<Toastmessage type='error' message='File too large' description='Each file should be less than 10mb'  />, { containerId: 'maxFileSizeError', closeOnClick:false, autoClose:false})
            }else if(error.statusCode === 403){
                toast.error(<Toastmessage type='error' message='Forbidden' />, { containerId:'forbidden'})
            }
            else  {
                toast.error(<Toastmessage type='error' message='Error while uploading' />, { containerId:'error'})
                
            }
            
            
        }
        

    }

    /*onFileDownload = (survey, options)  => {
        console.log('Optios are ', options);
        fileUpload.downloadFile(options.content, this.props.accessToken).then(blob => {
            var reader = new FileReader();
            reader.onload = (e) => {
                options.callback("success", e.target.result);
            };
            reader.readAsDataURL(new File([blob], options.fileValue.name, { type: options.fileValue.type }));
            options.callback("success", URL.createObjectURL(blob));
        }).catch(error => {
            console.log('error is ', error);
        })

    }*/


    /*valueChangeEvent = (sender, options) => {
        //console.log('Survey response after value change is ', this.state.surveyResponse)
        const surveyResponse = Object.assign({}, this.state.surveyResponse, { [options.name]: options.value});
        console.log('survey response after update is ', surveyResponse)
        this.setState({surveyResponse: surveyResponse})
    }*/

    removeFiles = async(sender, options) => {
        this.setState({ isSaving: true})
        let filesTodelete = [];
        if(options.fileName){
           let  file = this.state.files.filter(item =>  item.originalName === options.fileName);
           
            filesTodelete.push(file[0].filename)
        }else {
            let filesForQuestion = this.state.files.filter(file => file.questionName === options.name);
            filesTodelete = filesForQuestion.map(item => item.filename);
        }
        try {
            let body = {
                files: filesTodelete
            }
            let res = await fileUpload.deleteFile(body);
            
            const updatedFiles = this.state.files.filter(item => {
                if(!filesTodelete.includes(item.filename)){
                    return item;
                }
            });
            this.setState({ files: updatedFiles, isSaving: false});
            options.callback("success", (options.value|| []).filter(item => item.filename !== options.fileName))
        } catch (error) {
            toast.error(<Toastmessage type='error' message='Error while removing attachment' ></Toastmessage>, { containerId: 'removeError'})
            this.setState({ isSaving: false})
        }
    }

    onCurrentPageChanged = (survey, options) => {
        //isNextPage: true, isPrevPage: fals
        if(options.isNextPage){
            this.setState({currentPage: this.state.currentPage+1});
        }
        if(options.isPrevPage){
            this.setState({currentPage: this.state.currentPage-1});
        }
    }

    initializeSurveyResponse = () => {
        const json = this.props.assesmentForm.jsonForm;
        let model = new Survey.Model(json);
        //const radioQuestion = (model.getAllQuestions() || []).filter(question => question.getType() === 'radiogroup');
        model.getAllQuestions().forEach(item => {
            if(item.defaultValue){
                model.setValue(item.name, item.defaultValue);
                
            }
        });
        if(this.state.surveyResponse){
            model.data = this.state.surveyResponse
        }
        //this.setState({surveyResponse: surveyResponse})
        //model.data = surveyResponse
        model.currentPageNo = this.state.currentPage;
        this.setState({ surveyModel: model});
    }

    matrixColumnHasValue = (params) => {
        if(!params || params.length !== 3) return false;
        const arr = params[0];
        const colName = params[1];
        const value = params[2];
        let valueInArr = (arr || []).find(item => item[colName] === value)
        if(valueInArr) return true
        return false;
    }

    saveAsDraftClick = (event) => {
        event.preventDefault();
        this.setState({ showSaveAsDraftModel: true})
    }

    onSaveConfirmClick = (event, resumeLater) => {
        event.preventDefault();
        this.setState({isSaving: true, showPrompt: false, surveyResponse: this.state.surveyModel.data, showSaveAsDraftModel:false});
        let assesmentResponse = {
            assesmentFormId: this.props.assesmentForm.assesmentFormId,
            response: this.state.surveyModel.data,
            files: this.state.files,  
        };
        assessment.saveAssessmentAsDraft(assesmentResponse).then(res => {
            console.log('Response is ', res);
            if(resumeLater){
                assessment.sendDraftLinkAsEmail(res.assesmentResponse.id).then(res => {
                    this.props.history.replace('/user/assessment/success?savedAsDraft=true')
                })
            }else {
                this.setState({ isSaving: false})
                const assessmentId = res.assesmentResponse.id;
                this.props.draftSaveSuccess()
                this.props.history.replace(`/user/assessment/edit/${assessmentId}`)
            }
           
        }).catch(err => {
            this.setState({ isSaving: false})
        })
    }

    
}

const mapStateToProps = (state) => {
    return {
        assesmentForm: state.consumerAssesmentForm.consumerAssesmentForm,
        isAuthenticated: state.login.isAuthenticated,
        assessmentSubmitted: state.consumerAssesmentForm.assessmentSubmitted,
        getAssessmentError: state.consumerAssesmentForm.getAssessmentError,
        postAssessmentError: state.consumerAssesmentForm.postAssessmentError
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({ submitAssesmentResponse, getAssesmentFormForConsumer, resetError, draftSaveSuccess },dispatch)}
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )( SelfAssesmentForm)