import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserInfo } from '../../actions/userAction';
import { Col, Container, Row, Label, Button} from 'reactstrap';
import BackgroundImage from '../../assets/checkOutImage.png';
import DefaultLayout from '../../components/DefaultLayout';
import  { ScaleLoader } from 'react-spinners';
import moment from 'moment';


const styles = {
    formLabel: {
        height: '14px',
        color: '#0D0D0D',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center'
    }
}
class CheckOutSuccess extends Component {

    constructor(props){
        super(props)
        this.state = {
            isLoading: false,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true});
        this.props.getUserInfo();
    }

    componentDidUpdate(){
        if(this.props.user && this.state.isLoading){
            this.setState({ isLoading: false});
        }
        if(this.props.error && this.state.isLoading){
            this.setState({ isLoading: false});
        }
    }

    renderLoader(){
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {/* <Loader  type={loader} color="#1AB394" height={100} width={100} /> */}
                        <ScaleLoader color='#1AB394' height={100} width={10} radius={4} margin={4}/>
                    </Row>
                </Container>
            </div>
           
        )
    }

    render(){
        return (
            <React.Fragment>
                <DefaultLayout/>
                {
                this.state.isLoading ? this.renderLoader() :
                <div className="app flex-row align-items-center mt-n5" >
                    <Container className='mt-n5'>
                        <Row className="justify-content-center">
                            <Col lg="6" xs="12" sm={10}>
                                <img src={BackgroundImage} style={{width: '100%'}} alt='Background' />
                                
                            </Col>
                        </Row>
                        
                        <div className='justify-content-center row mt-5'>
                            <p className= 'checkout-text' >Check out Successfull!</p>
                        </div>
                        
                        <div className="justify-content-center row mt-5">
                            <p className= 'checkout-time' >{moment(new Date()).format('DD.MM.YYYY - hh:mm A')}</p>
                        </div>

                        <div className='justify-content-center row mt-5'>
                            <Label style={styles.formLabel}>Mobile Number</Label>
                        </div>
                        {this.props.user && <div className="justify-content-center row mt-2">
                            <Label className='registered-number'>{this.props.user.contactPhone}</Label>
                        </div>}
                        
                    </Container>
                </div>
                }
                
        </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user:state.user.user,
        error: state.user.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return { ...bindActionCreators({  getUserInfo  }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutSuccess)
