import NetworkManager from '../API/NetworkManager';

class Assessment {

    saveAssessmentAsDraft(assessmentResponse) {
        return NetworkManager.saveAssessmentAsDraft(assessmentResponse)
    }

    sendDraftLinkAsEmail(assessmentId) {
        return NetworkManager.sendEmailWithDraftURL(assessmentId)
    }

    updateAssessmentDraft(assessmentResponse, assessmentId) {
        return NetworkManager.updateAssesmentResponse(assessmentResponse, assessmentId)
    }
    
}

export default new Assessment()