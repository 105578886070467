import NetworkManager from '../API/NetworkManager';
import { GET_CONSUMER_ASSESMENT_FORM, GET_ASSESMENT_FORMS_ERROR, ASSESSMENT_SUBMITTED_ERROR, ASSESSMENT_SUBMITTED_SUCCESS, RESET_ALL_ERROR, GET_ASSESMENT_RESPONSE_SUCCESS, GET_ASSESMENT_RESPONSE_ERROR, ASSESSMENT_DRAFT_SUCCESS } from './actions';

export const getAssesmentFormForConsumer = (assesmentFormId) => {
    return  (dispatch, getState) => {
        NetworkManager.getAssesmentFormForConsumer(assesmentFormId).then(assesmentForm => {
            dispatch(getConsumerAssesmentFormSuccess(assesmentForm));
    }).catch(error => {
        dispatch(assesmentFormError(error));
    })
    }
}

export const getAssesmentForConsumer = (assesmentId) => {
    return  (dispatch, getState) => {
        NetworkManager.getAssesmentForConsumer(assesmentId).then(res => {
            
            dispatch(getAssesmentResponseSuccess(res.assesmentResponse));
    }).catch(error => {
        dispatch(getAssesmentResponseError(error));
    })
    }
}

export const draftSaveSuccess = () => {
    return { type: ASSESSMENT_DRAFT_SUCCESS }
}

export const submitAssesmentResponse = (assesmentResponse) => {
    return  (dispatch) => {
        dispatch(resetError());
        NetworkManager.submitAssesmentResponse(assesmentResponse).then(result => {
           localStorage.removeItem('_accessToken');
            console.log('Result from service', result);
           dispatch(assessmentSubmittedSuccess());
        }).catch(error => {
            console.log('Eror from service', error);
            dispatch(assessmentSubmitError(error));
        })
    }
}

export const updateAssesmentResponse = (assesmentResponse, assesmentId, action) => {
    return  (dispatch) => {
        dispatch(resetError());
        NetworkManager.updateAssesmentResponse(assesmentResponse, assesmentId).then(result => {
            if(action === 'submit')
                localStorage.removeItem('_accessToken');
            console.log('Result from service', result);
           dispatch(assessmentSubmittedSuccess());
        }).catch(error => {
            console.log('Eror from service', error);
            dispatch(assessmentSubmitError(error));
        })
    }
}

export const saveAssessmentAsDraft = (assesmentResponse) => {
    return  (dispatch) => {
        dispatch(resetError());
        NetworkManager.saveAssessmentAsDraft(assesmentResponse).then(result => {
           dispatch(assessmentSubmittedSuccess());
        }).catch(error => {
            console.log('Eror from service', error);
            dispatch(assessmentSubmitError(error));
        })
    }
}


const getConsumerAssesmentFormSuccess = (payload) => {
    return { type: GET_CONSUMER_ASSESMENT_FORM, payload}
}

const assesmentFormError = (payload) => {
    return { type: GET_ASSESMENT_FORMS_ERROR, payload}
}

const assessmentSubmittedSuccess = (payload) => {
    return { type: ASSESSMENT_SUBMITTED_SUCCESS, payload}
}

const assessmentSubmitError = (payload) => {
    return { type: ASSESSMENT_SUBMITTED_ERROR, payload}
}
export const resetError = (payload) => {
    return { type: RESET_ALL_ERROR, payload}
}

const getAssesmentResponseSuccess = (payload) => {
    return { type: GET_ASSESMENT_RESPONSE_SUCCESS, payload}
}

const getAssesmentResponseError = (payload) => {
    return { type: GET_ASSESMENT_RESPONSE_ERROR, payload}
}