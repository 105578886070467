import React, { Component } from 'react';
import { Col, Container, Row, Label, Button} from 'reactstrap';
import BackgroundImage from '../../assets/success.png';
import DefaultLayout from '../../components/DefaultLayout';


const styles = {
    background: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    buttonStyle: {
        height: '57px',
        width: '265px',
        borderRadius: '36.5px',
        backgroundColor: '#2768D4',
        color:' #FFFFFF',
        fontSize: '24px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '29px'
    },
    formLabel: {
        height: '44px',
        width: '257px',
        color: '#1F1F1F',
        fontSize: '16px',
        letterSpacing: 0,
        lineHeight: '22px',
        textAlign: 'center'
    }
}
class CheckInSuccess extends Component {

    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return (
            <React.Fragment>
                <DefaultLayout/>
                <div className="app flex-row align-items-center" >
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="6" xs="12" sm={10}>
                            <img src={BackgroundImage} style={{width: '100%'}} alt='Background' />
                            
                        </Col>
                    </Row>
                    
                    <div className='justify-content-center row'>
                        <p className= 'success-text' >Check In Successfull!</p>
                    {/* <Label style={styles.formLabel} className='mt-4'>Thank you for completing the declaration form. </Label> */}
                    </div>
                    
                    <div className="justify-content-center row mt-3">
                        <Button style={styles.buttonStyle} type='submit' onClick={this.badgeButtonClick}>My Badge</Button>
                    </div>
                    
                   
                </Container>
            </div>
        </React.Fragment>
        )
    }

    badgeButtonClick = () => {
        this.props.history.push('/user/badge');
    }
}

export default CheckInSuccess;