import React, { Component } from 'react';
import { Card, CardBody, CardGroup, Col, Container, Row} from 'reactstrap';
import BackgroundImage from '../../assets/login-bg.png';
import {ReactComponent as VoteLogo} from '../../assets/vote.svg';
import qs from 'qs';

const styles = {
    background: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }
}
class CompletedPage extends Component {

    constructor(props){
        super(props);
        this.state = {
            savedAsDraft: false
        }
    }

    componentDidMount() {
        //savedAsDraft
        localStorage.clear();
        let { search } = this.props.location
        if (search) {
            let queryParam = qs.parse(search, { ignoreQueryPrefix: true });
            if(queryParam.savedAsDraft){
                this.setState({ savedAsDraft: true})
            }
        }
    }

    render(){
        return (
            <React.Fragment>
            <div className="app flex-row align-items-center" style={styles.background}>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="6">
                        <CardGroup>
                            <Card className="p-4 opacity-85">
                               
                                <CardBody>
                                    <div style={{textAlign: 'center'}}>
                                        <VoteLogo style={{width: '100px', height: '100px'}} />
                                        <h2 style={{marginTop: '1.5rem'}}>{this.state.savedAsDraft ? 'Your assessment has been saved!' : 'Thank you for completing the assessment'}</h2>
                                        {this.state.savedAsDraft && 
                                        <p>An Email with URL link has been send to your email address. Please use that link to edit your assessment.</p>}
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
        </div>
        </React.Fragment>
        )
    }
}

export default CompletedPage;