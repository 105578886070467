import NetworkManager from '../API/NetworkManager';
import { USER_LOGIN_SUCCESS, USER_LOGIN_ERROR ,
     ASSESSMENT_FORM_INFO_SUCCESS, GET_ASSESSMENT_FORM_ERROR, 
     RESET_LOGIN_ERROR, VERIFY_OTP_ERROR, VERIFY_OTP_SUCCESS, SEND_OTP_ERROR, SEND_OTP_SUCCESS ,VERIFY_TOKEN_SUCCESS, VERIFY_TOKEN_ERROR} from './actions';
import storageService from '../services/StorageService';
import url from '../API/url';

export const login = (data, history) => {
    return  (dispatch) => {
        dispatch(resetError())
        NetworkManager.loginWithPassword(data).then(response => {
            storageService.setItem('_accessToken', response.access_token);
            storageService.setItem('_username', response.username);
            dispatch(loginSuccess({assesmentFormId: response.assesmentFormId}));
        }).catch(error=> {
            console.log('error is ', error);
            dispatch(loginError(error));
        });
    }
   
}

export const verifyOtp = (data, isAssessment) => {
    return (dispatch) => {
        dispatch(resetError())
        let verifyOTpUrl = isAssessment ? `${url.USER}/otp/verify` : `${url.CONSUMER_OTP}/verify`
        NetworkManager.verifyOTP(data, verifyOTpUrl).then(response => {
            storageService.setItem('_accessToken', response.access_token);
            storageService.setItem('_username', response.username);
            dispatch(verifyOtpSuccess({ id: response.assessmentId }))
        }).catch(error => {
            dispatch(verifyOtpError(error));
        })
    }
}

export const verifyToken = () => {
    return (dispatch) => {
        dispatch(resetError())
        if(!storageService.getItem('_accessToken')){
            dispatch(verifyTokenError({statusCode: 404, message: 'Token Not present'}));
             return;
        }
        const data = {
            access_token: storageService.getItem('_accessToken')
        }
        NetworkManager.verifyToken(data).then(response => {
            dispatch(verifyTokenSuccess())
        }).catch(error => {
            dispatch(verifyTokenError(error));
        })
    }
}

export const sendOtp = (data, isAssessment) => {
    return (dispatch, getState) => {
        dispatch(resetError())
        let sendOtpUrl = isAssessment ? `${url.USER}/otp` : `${url.CONSUMER_OTP}` ;
        NetworkManager.sendOtp(data, sendOtpUrl).then(res => {
            console.log('response is ', res);
            dispatch(sendOtpSuccess({otpGenerated: true, email: res.email}))
        }).catch(error => {
            dispatch(sendOtpError(error))
        })
    }
}

export const resendOtp = (data) => {
    return (dispatch) => {
        dispatch(resetError())
        let sendOtpUrl =  `${url.CONSUMER_OTP}/resend` 
        NetworkManager.sendOtp(data, sendOtpUrl).then(res => {
            dispatch(sendOtpSuccess(res.data))
        }).catch(error => {
            dispatch(sendOtpError(error))
           
        })
    }
}

export const getAssesmentFormInfo = (token) => {
    return (dispatch) => {
        dispatch(resetError());
        NetworkManager.getAssesmentFormForToken(token).then(response => {
            dispatch(getAssesmentInfoSuccess(response.assesmentForm));
            
        }).catch(error => {
            dispatch(assessmentFormError(error.error))
        });
    }
}

export const loginSuccess = (payload) => {
    return { type: USER_LOGIN_SUCCESS , payload}
}

export const loginError = (payload) => {
    return { type: USER_LOGIN_ERROR , payload}
}

export const assessmentFormError = (payload) => {
    return { type: GET_ASSESSMENT_FORM_ERROR, payload}
}

export const getAssesmentInfoSuccess = (payload) => {
    return { type: ASSESSMENT_FORM_INFO_SUCCESS, payload: payload}
}

export const resetError = (payload) => {
    return { type : RESET_LOGIN_ERROR, payload}
}

const verifyOtpSuccess =( payload) => {
    return { type: VERIFY_OTP_SUCCESS, payload}
}

const verifyOtpError = (payload) => {
    return { type: VERIFY_OTP_ERROR, payload}
}

const sendOtpSuccess =(payload) => {
    return { type: SEND_OTP_SUCCESS, payload}
}

const sendOtpError = (payload) => {
    return { type: SEND_OTP_ERROR, payload}
}

const verifyTokenSuccess =(payload) => {
    return { type: VERIFY_TOKEN_SUCCESS, payload}
}

const verifyTokenError = (payload) => {
    return { type: VERIFY_TOKEN_ERROR, payload}
}

