import NetworkManager from '../API/NetworkManager';
import { CHECKIN_SUCCESS, CHECKIN_ERROR, GET_USER_BADGE_ERROR, GET_USER_BADGE_SUCCESS, GET_PREVIOUS_RESPONSE_SUCCESS, GET_PREVIOUS_RESPONSE_ERROR, RESET_ALL_ERROR, CHECKOUT_SUCCESS, CHECKOUT_ERROR, USER_VERIFICATION_SUCCESS, USER_VERIFICATION_ERROR } from './actions';

export const getPreviousResponses = () => {
    return  (dispatch) => {
        
        NetworkManager.getPreviousResponses().then(response => {
           dispatch(getPreviousResponsesSuccess(response));
        }).catch(error=> {
           dispatch(getPreviousResponsesError(error));
        });
    }
}

export const submitResponse = (data) => {
    return  (dispatch) => {
        NetworkManager.submitResponse(data).then(response => {
           dispatch(assessmentSubmittedSuccess(response));
        }).catch(error=> {
           dispatch(assessmentSubmitError(error));
        });
    }
}

export const checkout = (data) => {
    return  (dispatch) => {
        NetworkManager.checkout(data).then(response => {
           dispatch(checkOutSuccess(response));
        }).catch(error=> {
           dispatch(checkOutError(error));
        });
    }
}

export const getUserInfo = () => {
    return (dispatch) => {
        NetworkManager.getUserInfo().then(res => {
            dispatch(userBadgeSuccess(res.user))
        }).catch(error => {
            dispatch(userBadgeError(error))
        })
    }
}

export const verifyUserQR = (userid, data) => {
    return (dispatch) => {
        NetworkManager.verifyUserStatus(userid, data).then(res => {
            dispatch(userVerifySuccess(res.user))
        }).catch(error => {
            dispatch(userVerifyError(error))
        })
    }
}

export const  resetError = () => {
    return { type: RESET_ALL_ERROR}
}

const assessmentSubmittedSuccess = (payload) => {
    return { type: CHECKIN_SUCCESS, payload}
}

const assessmentSubmitError = (payload) => {
    return { type: CHECKIN_ERROR, payload}
}

const userBadgeSuccess = (payload) => {
    return { type: GET_USER_BADGE_SUCCESS, payload}
}

const userBadgeError = (payload) => {
    return { type: GET_USER_BADGE_ERROR, payload}
}

const userVerifySuccess = (payload) => {
    return { type: USER_VERIFICATION_SUCCESS, payload}
}

const userVerifyError = (payload) => {
    return { type: USER_VERIFICATION_ERROR, payload}
}

const getPreviousResponsesSuccess = (payload) => {
    return { type: GET_PREVIOUS_RESPONSE_SUCCESS, payload}
}

const getPreviousResponsesError = (payload) => {
    return { type: GET_PREVIOUS_RESPONSE_ERROR, payload }
}

const checkOutSuccess = (payload) => {
    return { type: CHECKOUT_SUCCESS, payload}
}

const checkOutError = (payload) => {
    return { type: CHECKOUT_ERROR, payload}
}