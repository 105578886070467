import React, { Component } from 'react';
import * as Survey from "survey-react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Row} from 'reactstrap';
import { getEntityDetail } from '../../actions/entityAction';
import { submitResponse, getPreviousResponses, resetError, checkout } from '../../actions/userAction';
import fileUpload from '../../services/FileUpload';
import imageCompression from 'browser-image-compression';
import  { ScaleLoader, ClipLoader} from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import Toastmessage from '../../components/ToastMessage';
import DefaultLayout from '../../components/DefaultLayout';
import CheckInButton from '../../components/CheckInButton';
import moment from 'moment';

Survey.StylesManager.applyTheme("modern");

class SubmitAssessment extends Component {

    constructor(props){
        super(props);
        this.state = {
            jsonForm: null,
            formId:'',
            formName: '',
            result: null,
            mode:'',
            surveyResponse: null,
            surveyModel: null,
            files: [],
            currentPage: 0,
            isLoading: false,
            isFileUploading: false,
            isSaving:false,
            showPrompt: false,
            showErrorAlert: false,
            entityId:null,
            showDeclarationTemplate:false,
            isCheckedIn:false,
            previousResponseFetched:false,
            previousCheckInsForEntity:[]
            
        }
        this.compressionOption = {
            maxSizeMB: 2,
            maxWidthOrHeight:1024
          }
        this.onComplete = this.onComplete.bind(this);
    }

    componentDidMount() {
        let { params } = this.props.match;
        if(params && params.entityId){
            this.setState({ isLoading: true, entityId: params.entityId});
            this.props.getPreviousResponses()
            this.props.getEntityDetail(params.entityId);
        } 
    }

    componentDidUpdate(){
        
        if(this.props.entity && this.props.previousResponseFetched && this.state.isLoading){
            this.setState({ isLoading: false});
            const { checkedIn, previousCheckInsForEntity } = this.checkIfUserAlreadyCheckedIn();

            if(!checkedIn){
                this.checkInUser()
            }
        }
        
        if(this.props.getEntityError && this.state.isLoading){
            this.setState({ isLoading: false});
            this.props.history.push('/error')
        }
        
        if(this.props.assessmentSubmitted && this.state.isSaving){
            this.setState({isSaving: false});
            this.props.history.replace('/entity/checkIn/success');
        }
        if(this.props.postAssessmentError && this.state.isSaving){
            this.setState({isSaving: false });
            this.renderErrorAlert()
            if(this.props.entity.isDeclarationRequired){
                this.initializeSurveyResponse()
            }
        }

        if(this.props.checkoutSuccess && this.state.isSaving){
            this.setState({isSaving: false});
            this.props.history.replace('/entity/checkout/success');
        }

        if(this.state.showPrompt){
            window.onbeforeunload = () => true
        }
        
    }

    componentWillUnmount(){
        window.onbeforeunload = null;
    }

    renderForm(json, mode) {
        return(
            <div className="surveyjs">
               <Survey.Survey
            model={this.state.surveyModel}
            onComplete={this.onComplete}
            showCompletedPage={false}
            onUploadFiles={this.onFileUpload}
            onClearFiles={this.removeFiles}
            onCurrentPageChanged={this.onCurrentPageChanged}
            //onDownloadFile={this.onFileDownload}
            //onValueChanged={this.valueChangeEvent}
          />
          </div>
            //</div>
            
        )
    }

    renderErrorAlert(){
        if(this.props.postAssessmentError && this.props.postAssessmentError.error){
            toast.error(<Toastmessage type='error' message='Error while submitting form' description={this.props.postAssessmentError.error} ></Toastmessage>, { autoClose: false, closeOnClick:false, onClose: this.props.resetError})
        }else {
            toast.error(<Toastmessage type='error' message='Error while submitting form' ></Toastmessage>, {onClose: this.props.resetError})
        }
       
    }

    render(){
        
        let loaderActive = this.state.isFileUploading || this.state.isSaving
        return(
            <React.Fragment>
                <DefaultLayout hideBrand/>
                <div className="container">
                {this.state.isLoading ? this.renderLoader() 
                :
                <LoadingOverlay 
                    active={loaderActive} 
                    spinner={this.state.isSaving ? this.renderLoader() : <ClipLoader size={50} color='#1AB394'/>}
                    styles={{
                        overlay: (base) => ({
                          ...base,
                          background: 'none'
                        })
                      }}
                >
                    {!this.state.showDeclarationTemplate ? <CheckInButton checkOutClick={this.checkOut} checkInClick={this.checkIn}/>
                    :
                    this.renderForm(this.state.jsonForm, this.state.mode)}
                </LoadingOverlay> }
               
           </div>
            </React.Fragment>
            
        )
    }
    
    renderLoader(){
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {/* <Loader  type={loader} color="#1AB394" height={100} width={100} /> */}
                        <ScaleLoader color='#1AB394' height={100} width={10} radius={4} margin={4}/>
                    </Row>
                </Container>
            </div>
           
        )
    }

    onComplete(result, options) {
        this.setState({isSaving: true, showPrompt: false, surveyResponse: result.data});
        let assesmentResponse = {
            isDeclarationRequired: this.props.entity.isDeclarationRequired,
            entityId: this.props.entity.id,
            entityType:this.props.entity.entityType,
            response: result.data,
            templateId:this.props.entity.templateId,
            files: this.state.files
        };

        this.props.submitResponse(assesmentResponse);
    }

    onFileUpload = async (sender, options) => {
        let formData = new FormData()
        this.setState({isFileUploading: true});
        formData.append('questionName', options.name);
        try {
            for(const file of options.files){
                if(file.type.includes('image')){
                    let compressedblob = await imageCompression(file, this.compressionOption);
                    let compFile = compressedblob
                    compFile.name = file.name;
                    compFile.lastModified = new Date();
                    formData.append('file', compFile, file.name);
                }else {
                    formData.append('file', file);
                }
            }
            
            let response = await fileUpload.uploadFileForCheckIn(formData);
            this.setState({isFileUploading: false});
            if(response.statusCode === 201){
                const previousFiles = this.state.files
                const filesFromResponse = response.data
                if(previousFiles.length === 0)
                    this.setState({files: [...filesFromResponse]});
                else {
                    this.setState({files: [...previousFiles, ...filesFromResponse]})
                }
                options.accept = true;
                toast.success(<Toastmessage type='success' message='Attachment uploaded'  ></Toastmessage>, { containerId:'attachmentSuccess', autoClose:2000})
                options.callback("success", options.files.map(file => {
                        return {
                            file: file,
                            content: (response.data || []).find(item => item.originalName === file.name).filename
                            }
                }))
            }
            
        } catch (error) {
            console.log('Error is ', error)
            this.setState({isFileUploading: false});
            options.accept = false;
            options.callback("success", [])
            if(error.statusCode === 400){
                toast.error(<Toastmessage type='error' message={(error.error || {}).message || 'Malicious Content'}  />)
            } else if(error.statusCode === 413){
                toast.error(<Toastmessage type='error' message='File Size too large'  />)
            }else if(error.statusCode === 403){
                toast.error(<Toastmessage type='error' message='Forbidden' />, { containerId:'forbidden'})
            }
            else  {
                toast.error(<Toastmessage type='error' message='Error while uploading' />, { containerId:'error'})
                
            }
            
            
        }
        

    }

    /*valueChangeEvent = (sender, options) => {
        //console.log('Survey response after value change is ', this.state.surveyResponse)
        const surveyResponse = Object.assign({}, this.state.surveyResponse, { [options.name]: options.value});
        console.log('survey response after update is ', surveyResponse)
        this.setState({surveyResponse: surveyResponse})
    }*/

    removeFiles = async(sender, options) => {
        this.setState({ isSaving: true})
        let filesTodelete = [];
        if(options.fileName){
           let  file = this.state.files.filter(item =>  item.originalName === options.fileName);
           
            filesTodelete.push(file[0].filename)
        }else {
            let filesForQuestion = this.state.files.filter(file => file.questionName === options.name);
            filesTodelete = filesForQuestion.map(item => item.filename);
        }
        try {
            
           // await fileUpload.deleteFile(body);
            
            const updatedFiles = this.state.files.filter(item => {
                if(!filesTodelete.includes(item.filename)){
                    return item;
                }
            });
            this.setState({ files: updatedFiles, isSaving: false});
            options.callback("success", (options.value|| []).filter(item => item.filename !== options.fileName))
        } catch (error) {
            toast.error(<Toastmessage type='error' message='Error while removing attachment' ></Toastmessage>, { containerId: 'removeError'})
            this.setState({ isSaving: false})
        }
    }

    onCurrentPageChanged = (survey, options) => {
        //isNextPage: true, isPrevPage: fals
        if(options.isNextPage){
            this.setState({currentPage: this.state.currentPage+1});
        }
        if(options.isPrevPage){
            this.setState({currentPage: this.state.currentPage-1});
        }
    }

    initializeSurveyResponse = () => {
        const json = this.props.entity.jsonForm;
        let model = new Survey.Model(json);
        //const radioQuestion = (model.getAllQuestions() || []).filter(question => question.getType() === 'radiogroup');
        model.getAllQuestions().forEach(item => {
            if(item.defaultValue){
                model.setValue(item.name, item.defaultValue);
                
            }
        });
        if(this.state.surveyResponse){
            model.data = this.state.surveyResponse
        }
        //this.setState({surveyResponse: surveyResponse})
        //model.data = surveyResponse
        model.currentPageNo = this.state.currentPage;
        this.setState({ surveyModel: model});
        return model
    }

    populateResponse = (model) => {
        const previousResponses = this.props.previousResponses.map(item => {
            return JSON.parse(item.response)
        });

        model.getAllQuestions().forEach(item => {
            const response = previousResponses.find(res => res && res.hasOwnProperty(item.name));
            if(response)
                model.setValue(item.name, response[item.name])
        })
    }

    checkIn = (event) => {
        event.preventDefault();
        this.checkInUser()
    }

    checkOut = (event) => {
        event.preventDefault();
        const data = {
            activityIds: this.state.previousCheckInsForEntity,
            entityId: this.state.entityId,
            entityType:this.props.entity.entityType
        }
        this.setState({isSaving: true});
        this.props.checkout(data);
    }

    
    checkIfUserAlreadyCheckedIn = () => {
        let previousCheckInsForEntity = []
        let checkedIn = false
        this.props.previousResponses.forEach(item => {
            if(item.entityId === this.state.entityId){
                const startOfDay = moment(new Date()).startOf('day');
                const endOfDay = moment(new Date());

                if(moment(item.checkInTime).isBetween(startOfDay, endOfDay)){
                    checkedIn= true
                    previousCheckInsForEntity.push(item.id)
                }
            }
          
        });
        this.setState({ previousCheckInsForEntity: previousCheckInsForEntity, isCheckedIn: checkedIn});
        return { checkedIn, previousCheckInsForEntity }
    }

    checkInUser = () => {

        if(this.props.entity.isDeclarationRequired){
            const model = this.initializeSurveyResponse();
            if(this.props.previousResponses.length>0 ){
                this.populateResponse(model)
                this.setState({ jsonForm: this.props.entity.jsonForm, showPrompt: true});
            }
            this.setState({ showDeclarationTemplate:true})
        }else {
            this.setState({isSaving: true, showPrompt: false});
            let assesmentResponse = {
                isDeclarationRequired: this.props.entity.isDeclarationRequired,
                entityId: this.props.entity.id,
                entityType:this.props.entity.entityType,
            };
    
            this.props.submitResponse(assesmentResponse);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        entity: state.entity.entity,
        isAuthenticated: state.login.isAuthenticated,
        getEntityError: state.entity.getEntityError,
        assessmentSubmitted: state.user.assessmentSubmitted,
        postAssessmentError: state.user.postAssessmentError,
        previousResponses: state.user.previousResponses,
        previousResponseFetched: state.user.previousResponseFetched,
        checkoutSuccess: state.user.checkoutSuccess,
        checkoutError:state.user.checkoutError,
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return {...bindActionCreators({ getEntityDetail, submitResponse, getPreviousResponses, resetError, checkout },dispatch)}
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )( SubmitAssessment)