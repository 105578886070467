import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import {  Navbar, NavbarToggler, NavbarBrand, Button, Container } from 'reactstrap';
import {  Drawer } from 'antd';
import withSizes from 'react-sizes'
import './DefaultLayout.css';
import storageService from '../../services/StorageService';

const headerStyle= {
    color: '#2768D4',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '23px'
}
class DefaultLayout extends Component {

    constructor(props){
        super(props);
        this.state = {
            mobileMenuOpen: false,
            isMobileMenu:false,
            tab:0,
            isTokenPresent:false
        }
    }

    componentDidMount(){
        if(storageService.getItem('_accessToken')){
            this.setState({isTokenPresent: true })
        }
    }

    toggle = (e) => {
        e.preventDefault();
        this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen});
    }

    toggleMobileMenuOpen = () => {
        if(!this.state.mobileMenuOpen) {
            if(this.props.isMobileMenu) {
                this.setState({mobileMenuOpen: true });
            }
        } else {
            this.setState({mobileMenuOpen: false });
        }
    }

    renderDrawerLinks = () => {
       return(
        <div className="app flex-row align-items-center row mt-n5">
            <Container className='mt-n5' style={{ height:'100%'}}>
            <span  onClick={() => console.log('FAQ here')} className="justify-content-center row drawerLabel">
                {/* <i className="fa fa-info mr-3" style={{marginTop: '.25rem'}}></i> */}
                FAQ
            </span>
            {this.state.isTokenPresent && <span  className="justify-content-center row mt-5 drawerLabel" style={{ color: '#00B961'}} 
                                onClick={() => { 
                                    this.setState({mobileMenuOpen: false}); 
                                    this.props.history.push('/user/badge')
                                }} >
                
                {/* <i className= 'fa fa-qrcode mr-3' style={{marginTop: '.25rem'}}></i> */}
                My Badge
            </span>}
            {this.state.isTokenPresent && <span  onClick={this.loginAsDifferentUser} className="justify-content-center row mt-5 drawerLabel" style={{ color: '#EA3927'}}>
                {/* <i className= 'fa fa-sign-out mr-3' style={{marginTop: '.25rem'}}></i> */}
                Logout
            </span>}
            </Container>
            
            
        </div>
       )
    }

    /*renderNavLinks = () => {
        return [
        this.state.isTokenPresent && <Menu.Item key = {100} style={{marginLeft: '-1rem'}}>
            <Button color='link' onClick={this.loginAsDifferentUser}>
                <i className= 'fa fa-sign-out mr-3'></i>
                Login As Different User
            </Button>
        </Menu.Item>,
        this.state.isTokenPresent && <Menu.Item key = {101} style={{marginLeft: '-1rem'}}>
            <Button color='link' onClick={() => { 
                                    this.setState({mobileMenuOpen: false}); 
                                    this.props.history.push('/user/badge')
                                }}>
                <i className= 'fa fa-user mr-3'></i>
                My Badge
            </Button>
        </Menu.Item>,
        <Menu.Item key = {102} style={{marginLeft: '-1rem'}}>
            <Button color='link' onClick={() => console.log('FAQ here')}>
                <i className="fa fa-info mr-3"></i>
                FAQ
            </Button>
        </Menu.Item>
        ]
    }*/

    render() {
        const { hideBrand } = this.props
        return (
            <div>
                <Navbar color="light" light expand="md" fixed='true'>
                    <NavbarBrand href="#" >Stop Spread</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} style={{ zIndex: '1' }} />
                    <Drawer
                        title='Stop Spread'
                        placement='right'
                        closable={true}
                        width={'100%'}
                        height={'100%'}
                        headerStyle={headerStyle}
                        onClose = { this.toggleMobileMenuOpen}
                        visible={ this.state.mobileMenuOpen }>
                  {/* <Menu>{this.renderNavLinks()}</Menu> */}
                  {this.renderDrawerLinks()}
              </Drawer>
                    {/* <Nav className="navbar-toggler" navbar>
                        <UncontrolledDropdown nav direction="down">
                            <DropdownToggle nav>
                                <i className='navbar-toggler-icon'></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={(e) => { e.preventDefault(); console.log('logout clicked') }}><i className="fa fa-lock"></i> Logout</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav> */}
                </Navbar>
            </div>
        )
    }

    loginAsDifferentUser = () => {
        this.setState({mobileMenuOpen: false });
        localStorage.clear();
        this.props.history.replace('/logout')

    }
}

const mapSizesToProps = (sizes) => ({
    windowWidth: sizes.width,
    isMobileMenu: sizes.width< 768
})
   

export default withRouter(withSizes(mapSizesToProps)(DefaultLayout))