import React, { Component } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import qs from 'qs';
import { bindActionCreators } from 'redux';
import { login, getAssesmentFormInfo, resetError, sendOtp, verifyOtp } from '../../actions/loginAction';
import { Col, Button, Form, FormGroup, Label, Input, FormText, Row, Container, CardGroup, CardBody, Popover, PopoverBody} from 'reactstrap';
import { Alert } from 'antd'
import { Card } from 'reactstrap'
import { validate } from 'email-validator';
import BackgroundImage from '../../assets/login-bg.png';
import LoadingOverlay from 'react-loading-overlay';
import  { ScaleLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ToastMessage from '../../components/ToastMessage';
import OtpInput from 'react-otp-input'

const styles = {
    headerStyle: {
        color: '#093AFF',
        fontSize: '30px',
        lineHeight: '28px',
        fontWeight: 'bold'
    },
    surveyTextStyle: {
        color: '#1B2733',
        fontSize: '18px',
        lineHeight: '22px',
        fontWeight: 'bold',
        letterSpacing: '0.14px'
    },
    textStyle: {
        color: '#636363',
        fontSize: '14px',
        lineHeight: '19px'
    },
    labelStyle: {
        color: '#636363',
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '18px'
    },
    inputStyle: {
        border: 0,
        outline: 0,
        borderBottom: '1px solid #DFE2E6'
    },
    buttonStyle: {
        width: '240px',
        borderRadius: '20px',
        backgroundColor: '#066861',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: '600',
        lineHeight: '18px',
        letterSpacing: '0.1px'
    },
    background: {
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    }
}

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
class UserLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: null,
            password: '',
            isemptyPassword: false,
            isSecure: false,
            isCaptcha: false,
            isContactRequired: false,
            contactEmail: '',
            captchaToken: '',
            isEmailEmpty: false,
            isCaptchaEmpty: false,
            isCaptchaExpired: false,
            isEstablishmentDetailsEmpty: false,
            establishmentDetails: '',
            assessmentFormFetched: false,
            isLoading: false,
            isAPICall: false,
            unauthorized: false,
            showOTPBox: false,
            otp: '',
            isOtpEmtpty:false,
            getOTPButton: false
        }
        this.onProceedButtonClick = this.onProceedButtonClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        let { search } = this.props.location
        if (search) {
            let queryParam = qs.parse(search, { ignoreQueryPrefix: true });
            if(queryParam.action && queryParam.action === 'edit'){
                this.setState({ token: queryParam.token, getOTPButton:true});
            } else {
                this.setState({ token: queryParam.token, isLoading: true });
                this.props.getAssesmentFormInfo(queryParam.token);
            }
           
        }

    }

    componentDidUpdate(){
        if(this.props.assesmentFormId && this.state.isAPICall){
            this.setState({isAPICall : false });
            this.props.history.replace(`/user/assessment/submit/${this.props.assesmentFormId}`)
        }

        if(this.props.assessmentFormInfo ){
            if( !this.state.assessmentFormFetched){
                this.setState({assessmentFormFetched : true})
            }
            if( this.state.isLoading){
                this.setState({isLoading : false})
            }
        }
        if(this.props.assessmentFormError && this.state.isLoading){
            this.setState({isLoading : false })
            if(!this.props.assessmentFormError.statusCode)
                this.renderAssessmentFormError()
        }

        if(this.props.loginError && this.state.isAPICall){
            this.setState({isAPICall : false })
            if(!this.props.loginError.statusCode){
                toast.error(<ToastMessage type='error' message='Error While loading assessment form'/>, { containerId: 'loginError'})
            }else {
                this.setState({ unauthorized: true})
            }
        }

        if(this.props.otpPayload && this.state.isLoading){
            this.setState({ isLoading: false, showOTPBox: true, getOTPButton:false});
        }
        if(this.props.otpError && this.state.isLoading){
            const error = this.props.otpError;
            let message = error.statusCode === 404 ? 'Assessment already submitted' : 'OTP sending failed.'
            let description = error.statusCode === 404 ? 'You have already submitted this assessment. To make any changes contact CAST team.' : undefined
            this.setState({isLoading: false});
            toast.error(<ToastMessage type='error' message={message} description={description} />, { containerId: 'otpError', closeOnClick:false, autoClose: description ? false : true})
            //this.props.history.replace('/error/404')
        }
        if(this.props.isOtpVerified && this.state.isAPICall){
            this.setState({isAPICall: false});
            const assessmentId = this.props.assessmentId
            this.props.history.replace(`/user/assessment/edit/${assessmentId}`);
        }
        if(this.props.verifyOtpError && this.state.isAPICall){
            console.log('error is ', this.props.verifyOtpError)
            this.setState({isAPICall: false});
            toast.error(<ToastMessage type='error' message='OTP verification failed.' />, { onClose: this.props.resetError})
        }
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    {/* <img src="./background.jpeg"></img> */}
                </div>
                <LoadingOverlay 
                            active={this.state.isLoading || this.state.isAPICall}
                            spinner={<ScaleLoader color='#1AB394' height={100} width={10} radius={4} margin={4}/>}
                            styles={{
                                overlay: (base) => ({
                                ...base,
                                background: 'none'
                                })
                            }}
                            >
                <div className="app flex-row align-items-center" style={styles.background}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="5">
                        {this.props.assessmentFormError && this.props.assessmentFormError.statusCode ? this.renderAssessmentFormError(this.props.assessmentFormError.statusCode): null}
                        <CardGroup>
                                <Card className="p-4 opacity-85">
                                   
                                    {this.state.assessmentFormFetched && this.props.assessmentFormInfo && !this.state.showOTPBox &&
                                    <CardBody>
                                        <Form onSubmit={this.onProceedButtonClick}>
                                        { this.props.assessmentFormInfo.heading1 && <h1>{this.props.assessmentFormInfo.heading1}</h1>}
                                            {  this.props.assessmentFormInfo.subHeading1 && <p>{this.props.assessmentFormInfo.subHeading1}</p>} 
                                            <FormGroup>
                                                { this.props.assessmentFormInfo.subHeading2 && <FormText >
                                                {this.props.assessmentFormInfo.subHeading2} 
                                                </FormText>}
                                            </FormGroup>

                                            { this.props.assessmentFormInfo.isContactRequired && <FormGroup >
                                                <span>
                                                <Label for="examplePassword" style={styles.labelStyle}>Email of Surveyee</Label>
                                                <sup>
                                                    <i id='Popover1' className='fa fa-info-circle ml-1'></i>  
                                                </sup>
                                                <Popover placement='right' isOpen={this.state.showInfo} target="Popover1" toggle={() => this.setState({ showInfo: !this.state.showInfo})}>
                                                    <PopoverBody>We will use this email for future communications. </PopoverBody>
                                                </Popover> 
                                                </span>
                                                
                                                <Input invalid={this.state.isEmailEmpty}
                                                    type="email"
                                                    name="contactEmail"
                                                    id="contactEmail"
                                                    autoComplete="off"
                                                    value={this.state.contactEmail}
                                                    onChange={this.handleChange} />
                                            </FormGroup>}
                                            { this.props.assessmentFormInfo.isEstablishmentDetailsRequired && <FormGroup >
                                                <Label for="examplePassword" style={styles.labelStyle}>Establishment Name</Label>
                                                <Input invalid={this.state.isEstablishmentDetailsEmpty}
                                                    type="email"
                                                    name="establishmentDetails"
                                                    id="establishmentDetails"
                                                    autoComplete="off"
                                                    value={this.state.establishmentDetails}
                                                    onChange={this.handleChange} />
                                            </FormGroup>}
                                            {this.props.assessmentFormInfo.isSecure && <FormGroup >
                                                <Label for="examplePassword" style={styles.labelStyle}>Survey Password</Label>
                                                <Input invalid={this.state.isemptyPassword || this.state.unauthorized}
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    autoComplete="off" />
                                                {this.state.unauthorized && 
                                                    <div style={{marginTop: '5px'}}>
                                                        <i className='fa fa-exclamation-triangle' style={{color :'#FF0000'}}></i>
                                                        <Label  style={{marginLeft:'5px', color: '#FF6347'}}>Incorrect Password</Label>
                                                    </div>}
                                            </FormGroup>}
                                            {this.props.assessmentFormInfo.isCaptcha && <FormGroup>
                                                <Label/>
                                                <ReCAPTCHA
                                                    className='gc-reset'
                                                    sitekey={SITE_KEY}
                                                    onChange={this.verifyCallback}
                                                    onExpired={() => {
                                                        this.setState({
                                                            isCaptchaExpired: true,
                                                            captchaToken: ''
                                                        })
                                                    }}
                                                    onErrored={() => {
                                                        this.setState({
                                                            isCaptchaExpired: true,
                                                            captchaToken: ''
                                                        })
                                                    }}
                                                />
                                                 {this.state.isCaptchaEmpty && 
                                                    <div style={{marginTop: '15px'}}>
                                                        <i className='fa fa-exclamation-triangle' style={{color :'#FF0000'}}></i>
                                                        <Label  style={{marginLeft:'5px', color: '#FF6347'}}>Please verify Captcha</Label>
                                                    </div>}
                                            </FormGroup>}
                                            <FormGroup className="justify-content-center" row style={{ marginTop: '55px' }}>
                                                {this.state.assessmentFormFetched && <Button
                                                    type='submit'
                                                    onClick={this.onProceedButtonClick}
                                                    style={styles.buttonStyle}>Start</Button>}
                                            </FormGroup>

                                        </Form>
                                    </CardBody>}
                                    {this.state.showOTPBox && 
                                        <CardBody>
                                            <Form>
                                                <FormGroup className='justify-content-center row'>
                                                    <Label className='formLabel'>Please enter the OTP that was sent to {(this.props.otpPayload || {}).email} .</Label>
                                                </FormGroup>
                                                <FormGroup style={{marginRight: '10px'}}>
                                                    <OtpInput
                                                        inputStyle="otpInputStyle"
                                                        numInputs={6}
                                                        value={this.state.otp}
                                                        errorStyle="error"
                                                        onChange={(otp) => { this.setState({ otp: otp, isOtpEmtpty: false })}}
                                                        shouldAutoFocus
                                                        hasErrored={this.state.isOtpEmtpty}
                                                        />
                                                </FormGroup>
                                                
                                                <FormGroup className="mt-5 justify-content-center" row>
                                                {<Button
                                                    type='submit'
                                                    onClick={this.onOTPProceedButton}
                                                    style={styles.buttonStyle}>Continue
                                                </Button>}
                                                </FormGroup>
                                                <FormGroup className='mt-4 justify-content-center row'>
                                                
                                                    <label className='float-right mt-2'>If you do not receive the OTP, please access the link again to re-send the OTP.</label>
                                                </FormGroup>
                                            </Form>
                                        </CardBody>
                                    }
                                    {this.state.getOTPButton && 
                                        <CardBody>
                                            <Form>
                                            <FormGroup className='mt-4 justify-content-center row'>
                                                <Label className='formLabel'>Get an OTP on your registered email address</Label>
                                            </FormGroup>
                                                <FormGroup className="justify-content-center" row>
                                                {<Button
                                                    type='submit'
                                                    onClick={this.sendOTP}
                                                    style={styles.buttonStyle}>GET OTP
                                                </Button>}
                                            </FormGroup>
                                            </Form>
                                        </CardBody>
                                    }
                                   
                                </Card>
                            </CardGroup>
                            
                            
                        </Col>
                    </Row>
                </Container>
               
            </div>
            </LoadingOverlay>
            </React.Fragment>
            
        )
    }

    renderAssessmentFormError(statusCode) {
        if(statusCode && statusCode === 4041){
            return (<Alert type="error" message='This form is currently deactivated.' description='Please contact the administrator to activate it' />)
        }else {
            toast.error(<ToastMessage type='error' message='Error while loading assessment form' />, { onClose: this.props.resetError, containerId:'formError'});
        }
    }

    onProceedButtonClick = (event) => {
        event.preventDefault();
        if (this.props.assessmentFormInfo.isSecure && this.state.password === '') {
            this.setState({ isemptyPassword: true });
            return;
        }
        if (this.props.assessmentFormInfo.isContactRequired) {
            if (this.state.contactEmail === '') {
                this.setState({ isEmailEmpty: true });
                return;
            }
            if (!validate(this.state.contactEmail)) {
                this.setState({ isEmailEmpty: true });
                return;
            }

        }

        if(this.props.assessmentFormInfo.isEstablishmentDetailsRequired){
            if (this.state.establishmentDetails === '') {
                this.setState({ isEstablishmentDetailsEmpty: true });
                return;
            }
        }
        if (this.props.assessmentFormInfo.isCaptcha) {
            if (this.state.captchaToken === '' || this.state.isCaptchaExpired) {
                this.setState({ isCaptchaEmpty: true })
                return;
            }
        }
        this.setState({ isAPICall: true})
        let data = {
            token: this.state.token
        }
        if (this.state.password !== '')
            data.password = this.state.password
        if (this.state.contactEmail !== '')
            data.contactEmail = this.state.contactEmail
        if (this.state.captchaToken !== '')
            data.captchaToken = this.state.captchaToken
         if (this.state.establishmentDetails !== '')
            data.establishmentDetails = this.state.establishmentDetails
        
        this.props.login(data)
    }

    onOTPProceedButton = (event) => {
        event.preventDefault();
        if(this.state.otp === '' || this.state.otp.length !== 6){
            this.setState({ isOtpEmtpty: true})
            return;
        }

        const data = {
            otp: this.state.otp,
            token: this.state.token
        }
        this.setState({ isAPICall: true})

        this.props.verifyOtp(data, true)

    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
        if (event.target.id === 'password') {
            this.setState({ isemptyPassword: false, unauthorized:false })
        }
        if (event.target.id === 'contactEmail') {
            this.setState({ isEmailEmpty: false })
        }
        if(event.target.id === 'establishmentDetails'){
            this.setState({ isEstablishmentDetailsEmpty: false })
        }
    };

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
            //this.captchaDemo.execute();
        }
    }
    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!  
        console.log(recaptchaToken, "<= your recaptcha token")
        this.setState({
            captchaToken: recaptchaToken,
            isCaptchaExpired: false,
            isCaptchaEmpty: false
        });
    }

    sendOTP = (event) => {
        event.preventDefault();
        this.setState({ isLoading: true});
        this.props.sendOtp({ token: this.state.token }, true)
    }

}
const mapStateToProps = (state) => {
    return {
        loginError: state.login.loginError,
        assessmentFormInfo: state.login.assessmentFormInfo,
        assessmentFormError: state.login.assessmentFormError,
        assesmentFormId: state.login.assessmentFormId,
        otpPayload: state.login.otpPayload,
        otpError: state.login.otpError,
        isOtpVerified:state.login.isOtpVerified,
        assessmentId: state.login.assessmentId,
        verifyOtpError: state.login.verifyOtpError
    }

}

const mapDispatchToProps = (dispatch) => {
    return { ...bindActionCreators({ login, getAssesmentFormInfo, resetError, sendOtp, verifyOtp }, dispatch) }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLogin)