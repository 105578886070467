import { RESET_ALL_ERROR, 
        CHECKIN_SUCCESS, 
        CHECKIN_ERROR, 
        GET_USER_BADGE_SUCCESS, 
        GET_USER_BADGE_ERROR, 
        GET_PREVIOUS_RESPONSE_SUCCESS, 
        GET_PREVIOUS_RESPONSE_ERROR,
        CHECKOUT_SUCCESS,
        CHECKOUT_ERROR,
        USER_VERIFICATION_SUCCESS,
        USER_VERIFICATION_ERROR
} from '../actions/actions';

const initialState = {
        assessmentSubmitted:false,
        postAssessmentError: null,
        user: null,
        error: null,
        previousResponses: [],
        apiError: null,
        previousResponseFetched: false,
        checkoutSuccess: false,
        checkoutError: null,
        verificationInfo: null
}
const userReducer = (state, action) => {
    switch(action.type){
        case CHECKIN_SUCCESS:
                return Object.assign({}, state, { assessmentSubmitted: true,});
        case CHECKIN_ERROR:
                return Object.assign({}, state, {postAssessmentError: action.payload, assessmentSubmitted: false});
        case GET_USER_BADGE_SUCCESS:
                return Object.assign({}, state, { user: action.payload,});
        case GET_USER_BADGE_ERROR:
                return Object.assign({}, state, {error: action.payload, });
        case GET_PREVIOUS_RESPONSE_SUCCESS:
                return Object.assign({}, state, { previousResponses: action.payload, previousResponseFetched: true});
        case GET_PREVIOUS_RESPONSE_ERROR:
                return Object.assign({}, state, {apiError: action.payload, });
        case RESET_ALL_ERROR:
                return Object.assign({}, state, { apiError: null, postAssessmentError: null, error: null, checkoutError: null});
        case CHECKOUT_SUCCESS:
                return Object.assign({}, state, { checkoutSuccess: true,});
        case CHECKOUT_ERROR:
                return Object.assign({}, state, {checkoutError: action.payload, checkoutSuccess: false});
        case USER_VERIFICATION_SUCCESS:
                return Object.assign({}, state, { verificationInfo: action.payload,});
        case USER_VERIFICATION_ERROR:
                return Object.assign({}, state, {error: action.payload });
        default:
                if(state)
                        return state;
                else
                        return initialState;
    }
}
export default userReducer;