import url from './url';
import storageService from '../services/StorageService'

const baseUrl = process.env.REACT_APP_BASE_URL
export default class NetworkManager {

    static async pingBaseUrl(){
        return fetch(`${baseUrl}/api`, { mode: 'no-cors'})
    }

    static async getAssesmentFormForToken(token) {
        let getAssesmentFormUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_FORM}/?token=${token}`;
        try {
            const resultJson = await this._call('GET', getAssesmentFormUrl)
            return resultJson;
         
        } catch (error) {
            console.log('Error is ', error)
            return Promise.reject(error);
        }
    }
    static async getAssesmentFormForConsumer(assesmentFormId) {
        let getAssesmentFormUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_FORM}/${assesmentFormId}`
       try {
           const resultJson = await this._call('GET', getAssesmentFormUrl);
           return resultJson.assesmentForm;
       } catch (error) {
           console.log('Error is ', error);
           return Promise.reject(error);
       }
    }

    static async getAssesmentForConsumer(assessmentId) {
        let getAssesmentUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}/${assessmentId}`
       try {
           const resultJson = await this._call('GET', getAssesmentUrl);
           return resultJson;
       } catch (error) {
           console.log('Error is ', error);
           return Promise.reject(error);
       }
    }

    static async submitAssesmentResponse(assesmentResponse ) {
        let submitAssesmentResponseUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}`;
        try {
            const resultJson = await this._call('POST', submitAssesmentResponseUrl, assesmentResponse);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async updateAssesmentResponse(assesmentResponse, assessmentId ) {
        let updateAssesmentResponseUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}/${assessmentId}`;
        try {
            const resultJson = await this._call('PATCH', updateAssesmentResponseUrl, assesmentResponse);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async saveAssessmentAsDraft(assesmentResponse ) {
        let saveAsDraftAssesmentResponseUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}/draft`;
        try {
            const resultJson = await this._call('POST', saveAsDraftAssesmentResponseUrl, assesmentResponse);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async sendEmailWithDraftURL(assessmentId ) {
        let sendEmailWithDraftURL = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}/${assessmentId}/email`;
        try {
            const resultJson = await this._call('POST', sendEmailWithDraftURL);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async loginWithPassword(loginRequest) {
        let loginUrl = `${baseUrl}${url.USER}/login`;
        try {
            const resultJson = await this._call('POST', loginUrl, loginRequest);
            return resultJson;
        } catch (error) {
            console.log('Error is ', error);
            return Promise.reject(error);
        }
    }

    static async uploadFile(path, formdata) {
        let uploadFileUrl = `${baseUrl}${path}/attachments`;
        try {
            const resultJson = await this._call('POST', uploadFileUrl, formdata);
            return resultJson;
       } catch (error) {
           return Promise.reject(error);
       }
    }

    static async downloadFile(fileName){
        let downloadFileUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}/attachments/${fileName}`;
        let accessToken = storageService.getItem('_accessToken');
        try {
            const result = await fetch(downloadFileUrl, 
                { 
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`
                }, 
            });
            const blob = await result.blob();
            return blob;
            
           } catch (error) {
               return Promise.reject(error);
           }
    }

    static async deleteFile(attachments){
        let deleteFileUrl = `${baseUrl}${url.CONSUMER_ASSESMENT_RESPONSE}/attachments`;
        try {
            
            const responsJson = await this._call('PATCH', deleteFileUrl, attachments);
            return responsJson;
            
        } catch (error) {
            return Promise.reject(error);
        }
    }


    static async getEntityDetails(entityId) {
        let getEntityUrl = `${baseUrl}${url.CONSUMER_ENTITY}/${entityId}`
       try {
           const resultJson = await this._call('GET', getEntityUrl);
           return resultJson.entity;
       } catch (error) {
           console.log('Error is ', error);
           return Promise.reject(error);
       }
    }


    static async submitResponse(data) {
        let activityURL = `${baseUrl}${url.USER_ACTIVITY}/checkin`
       try {
           const resultJson = await this._call('POST', activityURL, data);
           return resultJson;
       } catch (error) {
           console.log('Error is ', error);
           return Promise.reject(error);
       }
    }

    static async checkout(data) {
        let activityURL = `${baseUrl}${url.USER_ACTIVITY}/checkout`
       try {
           const resultJson = await this._call('POST', activityURL, data);
           return resultJson;
       } catch (error) {
           console.log('Error is ', error);
           return Promise.reject(error);
       }
    }

    static async getPreviousResponses() {
        let activityURL = `${baseUrl}${url.USER_ACTIVITY}`
       try {
           const resultJson = await this._call('GET', activityURL);
           return resultJson.activities;
       } catch (error) {
           console.log('Error is ', error);
           return Promise.reject(error);
       }
    }

    static async verifyOTP(data, verifyOtpUrl) {
        
        try {
            const resultJson = await this._call('POST', `${baseUrl}${verifyOtpUrl}`, data);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
        
    }

    static async sendOtp(data, sendOtpUrl) {
        
        try {
            const resultJson = await this._call('POST', `${baseUrl}${sendOtpUrl}`, data);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async verifyToken(data) {
        let verifyTokenUrl = `${baseUrl}/api/user/token/verify`
        try {
            const resultJson = await this._call('POST', verifyTokenUrl, data);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
        
    }

    static async getUserInfo() {
        let userUrl = `${baseUrl}/api/user`
        try {
            const resultJson = await this._call('GET', userUrl);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
        
    }

    static async verifyUserStatus(userId, data) {
        let userUrl = `${baseUrl}/api/user/${userId}/verify`
        try {
            const resultJson = await this._call('POST', userUrl, data);
            return resultJson;
        } catch (error) {
            return Promise.reject(error);
        }
        
    }

    static async _call(method= 'GET', path, body){
        let accessToken = storageService.getItem('_accessToken');
        let requestBody;
        let requestHeaders = {
            'Content-Type': 'application/json',
        }
        if(accessToken){
            requestHeaders['Authorization'] = `Bearer ${accessToken}`
        }
        if (body instanceof FormData) {
            // Leave FormData untouched
            requestHeaders = {
                Authorization: `Bearer ${accessToken}`
            };
            requestBody = body
        }else {
            requestBody = JSON.stringify(body)
        }
        try {
            let result = await fetch(path, {method: method,body: requestBody, mode: 'cors',headers: requestHeaders });
            let responseJson = await result.json();
            console.log('Response from serivce', responseJson);
            if(responseJson.statusCode === 200 || responseJson.statusCode === 201){
                return responseJson;
            }else {
                return Promise.reject(responseJson);
            }
        } catch (error) {
            console.log('error while calling serivice', error);
            return Promise.reject(error);
        }
        
    }
    
}