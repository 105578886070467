import NetworkManager from '../API/NetworkManager';
import { CONSUMER_ASSESMENT_RESPONSE, USER_ACTIVITY } from '../API/url';

class FileUpload {

    uploadFile(formData){
        return NetworkManager.uploadFile(CONSUMER_ASSESMENT_RESPONSE ,formData);
    }

    downloadFile(fileName) {
        return NetworkManager.downloadFile(fileName)
    }

    deleteFile(attachments) {
        return NetworkManager.deleteFile(attachments);
    }
    uploadFileForCheckIn(formData){
        return NetworkManager.uploadFile(USER_ACTIVITY,formData);
    }
}

export default new FileUpload()