import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { createHashHistory as createHistory } from 'history'
import rootReducer from './reducers'

export const history = createHistory()

const initialState = {}

const middleware = [thunk]

export default createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware),
)


// export default function configureStore(initialState) {
//     const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
//     return store;
//   }