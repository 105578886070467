import { combineReducers } from 'redux'
import consumerAssesmentForm from './consumerAssesmentFormReducer';
import login from './loginReducer';
import entity from './entityReducer';
import user from './userReducer';

const rootReducer =  combineReducers({
  consumerAssesmentForm,
  login,
  entity,
  user
});

export default rootReducer