import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, Col, Container, Row, Label, FormGroup} from 'reactstrap';
import { getUserInfo } from '../../actions/userAction';
import  { ScaleLoader } from 'react-spinners';
import AppleWalletImage from '../../assets/apple_wallet.jpg'
import DefaultLayout from '../../components/DefaultLayout';

var QRCode = require('qrcode.react');

const styles = {
    labelStyle: {
        height: '14px',
        color: '#0D0D0D',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center'
    },

    number:  {
        height: '2rem',
        color: '#03153E',
        fontSize: '28px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: '2rem'
      }
}
class Badge extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            badgeColor: ''
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true});
        this.props.getUserInfo();
    }

    componentDidUpdate(){
        if(this.props.user && this.state.isLoading){
            this.setState({ isLoading: false});
            switch(this.props.user.riskStatus){
                case 'green':
                    this.setState({ badgeColor: '#00B961'})
                    break;
                case 'yellow':
                    this.setState({ badgeColor: '#EEB31F'})
                    break;
                case 'red':
                    this.setState({ badgeColor: '#EA3927'})
                    break;
                default:
                    this.setState({ badgeColor: '#EA3927'})
                    break;
            }
        }
        if(this.props.error && this.state.isLoading){
            this.setState({ isLoading: false});
        }
    }

    renderLoader(){
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {/* <Loader  type={loader} color="#1AB394" height={100} width={100} /> */}
                        <ScaleLoader color='#2768D4' height={100} width={10} radius={4} margin={4}/>
                    </Row>
                </Container>
            </div>
           
        )
    }

    render(){
        return (
            <React.Fragment>
                <DefaultLayout/>
                
                {this.state.isLoading ? this.renderLoader() :

                <div className="app flex-row align-items-center">
                    {this.props.user && <Container>
                        <Row className="justify-content-center">
                            <Col xs="12">
                                <FormGroup>
                                    <h1 className='h1Label'>My Badge</h1>
                                </FormGroup>
                            </Col>
                            
                            {this.state.badgeColor && <Card className='mt-2'>
                                <QRCode 
                                    value={this.props.user.verifyURL} 
                                    size={250} 
                                    includeMargin={true} 
                                    fgColor={this.state.badgeColor} />
                            </Card>}
                        </Row>
            
                        <div className="justify-content-center row">
                            <img src={AppleWalletImage} style={{width: '150px', height:'100px'}} alt='Apple Wallet' ></img>
                            
                        </div>
                        
                        <div className="justify-content-center row">
                            <Label style={styles.labelStyle}>Registered Number</Label>
                        </div>
                        <div className="justify-content-center row mt-2">
                            <Label className='registered-number'>{this.props.user.contactPhone}</Label>
                            
                        </div>
                        <div className="justify-content-center row mt-2">
                            <Label className='info-text'>This is your unique personal profile badge. The different colour coding represents different levels of movement access.</Label>
                        </div>
                        
                        
                    </Container> }
                </div>}
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        user:state.user.user,
        error: state.user.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return { ...bindActionCreators({  getUserInfo  }, dispatch) }
}
export default connect(mapStateToProps, mapDispatchToProps)(Badge)
