import React, { Component } from 'react';
import {  Label} from 'reactstrap';
import Collapsible from 'react-collapsible';
import './Collapsible.scss';
class ToastMessage extends Component {

    constructor(props){
        super(props);
    }

    render() {
        const { description } = this.props
        return(
            <React.Fragment>
                <div style={{marginTop: '10px'}}>
                {! description && this.renderTriggerElement()}
               {description && <Collapsible trigger={this.renderTriggerElement()}>
                    {this.renderDescription()}
                </Collapsible>}
                </div>
            </React.Fragment>
        )
    }

    renderTriggerElement() {
        const { message, type,} = this.props
        let cssClass = ''
        switch(type) {

            case 'error':
                cssClass = 'fa fa-exclamation-circle';
                break;
                
            case 'success':
                cssClass = 'fa fa-check-circle';
                break;
               
            case 'warning':
                cssClass = 'fa fa-warning';
                break;
                
        }
        return (
            <span >
                <i className={cssClass}></i>
                <Label style={{marginLeft: '10px'}} >{message}</Label>
            </span>
        )
    }

    renderDescription() {
        const { description } = this.props;
        if(typeof description === 'string')
            return (<p>{description}</p>)
        return Object.keys(description).map((key, index ) => {
            return (<p key={index}>{`${key.toUpperCase()} : ${description[key]}`}</p>)
        })
    }
}

//ToastMessage.propTypes = propTypes

export default ToastMessage;