import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page500 from './views/Page500';
import Page404 from './views/Page404';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import '@coreui/coreui/dist/css/coreui.css';
import NetworkDetector from './components/NetworkDetector';
import {Provider} from 'react-redux';
import UserLogin from './views/UserLogin';
import * as Survey from "survey-react";
import * as SurveyCore from "survey-core";
import * as widgets from "surveyjs-widgets";
import {SelfAssesment, EditAssessment } from './views/SelfAssesment';
import CompletedPage from './views/CompletedPage';
import { UserCheckIn , CheckInSuccess } from './views/CheckIn';
import SubmitAssesment from './views/Assesment';
import  Badge from './views/Badge';
import Logout from './views/Logout';
import CheckOutSuccess from './views/Checkout';
import VerifyUser from './views/VerifyUser';

import $ from "jquery";
import "survey-react/survey.css";
import "bootstrap/dist/css/bootstrap.css";
import 'icheck/skins/square/blue.css';
import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
import 'survey-react/modern.css';


window["$"] = window["jQuery"] = $;
require( "emotion-ratings/dist/emotion-ratings.js");
require('icheck');
widgets.icheck(Survey, $);
widgets.icheck(SurveyCore, $);
widgets.prettycheckbox(Survey);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);
widgets.emotionsratings(Survey, $);
widgets.prettycheckbox(SurveyCore);
widgets.inputmask(SurveyCore);
widgets.jquerybarrating(SurveyCore, $);
widgets.jqueryuidatepicker(SurveyCore, $);
widgets.nouislider(SurveyCore);
widgets.select2tagbox(SurveyCore, $);
widgets.sortablejs(SurveyCore);
widgets.ckeditor(SurveyCore);
widgets.autocomplete(SurveyCore, $);
widgets.bootstrapslider(SurveyCore);
widgets.emotionsratings(SurveyCore, $);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends React.Component {

  render() {
    if (window.self !== window.top) return (<>This webpage can't be displayed in an iframe.</>)
    return (
      <div className="App">
       <Provider store={this.props.store}>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} hideProgressBar={true}/>
        <React.Suspense fallback={loading()}>
          {/* <DefaultLayout/> */}
        <Switch>
            <Route exact path="/" component={Page500} />
            {/* <Route  path="/user/login" component={UserLogin} /> */}
            <Route exact path="/user/selfAssesment" component={UserLogin}></Route>
            <Route exact path="/user/selfAssesment/submit/:assesmentId" component={SelfAssesment}></Route> 
            <Route exact path="/user/selfAssesment/success" component={CompletedPage}></Route>  

            <Route exact path="/user/assessment" component={UserLogin}></Route>
            <Route exact path="/user/assessment/submit/:assesmentId" component={SelfAssesment}></Route> 
            <Route exact path="/user/assessment/edit/:assesmentId" component={EditAssessment}></Route> 
            <Route exact path="/user/assessment/success" component={CompletedPage}></Route>  

            <Route exact path="/entity/checkIn" component={UserCheckIn}></Route>
            <Route exact path="/entity/checkIn/submit/:entityId" component={SubmitAssesment}></Route> 
            <Route exact path="/entity/checkIn/success" component={CheckInSuccess}></Route> 
            <Route exact path="/entity/checkout/success" component={CheckOutSuccess}></Route>  
            <Route exact path="/user/badge" component={Badge}></Route>
            <Route exact path="/user/verify/:userId" component={VerifyUser}></Route>
            <Route exact path="/error" component={Page500}></Route>  
            <Route exact path="/error/404" component={Page404}></Route>  
            <Route exact path="/logout" component={Logout}></Route> 
          </Switch>
        </React.Suspense>
      
       </Provider>
      </div>
    );
  }
  
}

export default NetworkDetector(App);
