import { GET_ENTITY_ERROR, GET_ENTITY_SUCCESS, RESET_ALL_ERROR } from '../actions/actions';

const initialState = {
        entity:null,
        getEntityError: null,
        postAssessmentError: null
}
const entityReducer = (state, action) => {
    switch(action.type){
        case GET_ENTITY_ERROR:
                return Object.assign({}, state, { getEntityError: action.payload});
        case GET_ENTITY_SUCCESS:
                return Object.assign({}, state, { 
                        entity: action.payload, 
                        getEntityError: null })
        case RESET_ALL_ERROR:
                return Object.assign({}, state, { getEntityError: null});
        default:
                if(state)
                        return state;
                else
                        return initialState;
    }
}
export default entityReducer;