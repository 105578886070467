export const GET_CONSUMER_ASSESMENT_FORM='GET_CONSUMER_ASSESMENT_FORM';
export const GET_ASSESMENT_FORMS_ERROR = 'GET_ASSESMENT_FORMS_ERROR';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const ASSESSMENT_FORM_INFO_SUCCESS = 'ASSESSMENT_FORM_INFO_SUCCESS';
export const GET_ASSESSMENT_FORM_ERROR = 'GET_ASSESSMENT_FORM_ERROR'
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR';
export const ASSESSMENT_SUBMITTED_SUCCESS = 'ASSESSMENT_SUBMITTED_SUCCESS';
export const ASSESSMENT_SUBMITTED_ERROR = 'ASSESSMENT_SUBMITTED_ERROR';
export const RESET_ALL_ERROR = 'RESET_ALL_ERROR';

export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_ERROR = 'GET_ENTITY_ERROR';

export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';
export const RESET_OTP_PAYLOAD = 'RESET_OTP_PAYLOAD'

export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR';

export const CHECKIN_SUCCESS = 'CHECKIN_SUCCESS';
export const CHECKIN_ERROR = 'CHECKIN_ERROR';

export const GET_USER_BADGE_ERROR = 'GET_USER_BADGE_ERROR';
export const GET_USER_BADGE_SUCCESS = 'GET_USER_BADGE_SUCCESS';

export const GET_PREVIOUS_RESPONSE_ERROR = 'GET_PREVIOUS_RESPONSE_ERROR';
export const GET_PREVIOUS_RESPONSE_SUCCESS = 'GET_PREVIOUS_RESPONSE_SUCCESS';


export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';

export const USER_VERIFICATION_SUCCESS = 'USER_VERIFICATION_SUCCESS'
export const USER_VERIFICATION_ERROR = 'USER_VERIFICATION_ERROR'

export const GET_ASSESMENT_RESPONSE_SUCCESS = 'GET_ASSESMENT_RESPONSE_SUCCESS';
export const GET_ASSESMENT_RESPONSE_ERROR = 'GET_ASSESMENT_RESPONSE_ERROR';

export const ASSESSMENT_DRAFT_SUCCESS = 'ASSESSMENT_DRAFT_SUCCESS'