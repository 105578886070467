import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import { Card, Col, Container, Row, Label, FormGroup, CardBody} from 'reactstrap';
import {  verifyUserQR, resetError } from '../../actions/userAction';
import { toast} from 'react-toastify';
import ToastMessage from '../../components/ToastMessage';
import  { ScaleLoader } from 'react-spinners';
import DefaultLayout from '../../components/DefaultLayout';
import moment from 'moment';
var QRCode = require('qrcode.react');

const styles = {
    labelStyle: {
        height: '14px',
        color: '#0D0D0D',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center'
    },

    number:  {
        height: '2rem',
        color: '#03153E',
        fontSize: '28px',
        fontWeight: 'bold',
        letterSpacing: '0',
        lineHeight: '2rem'
      }
}
class VerifyUser extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            badgeColor: '',
            userId: null,
            status: ''
        }
    }

    componentDidMount() {
        let { params } = this.props.match;
        let { search } = this.props.location
        if(params && params.userId && search ){
            let queryParam = qs.parse(search, { ignoreQueryPrefix: true });
            const status = queryParam.status;
            this.setState({ isLoading: true, userId: params.userId, status: status});
            const data = {
                status: status
            }
            this.props.verifyUserQR(params.userId, data);
        } 
        //this.props.getUserInfo();
    }

    componentDidUpdate(){
        if(this.props.verificationInfo && this.state.isLoading){
            this.setState({ isLoading: false});
            switch(this.props.verificationInfo.riskStatus){
                case 'normal':
                    this.setState({ badgeColor: '#00B961'})
                    break;
                case 'suspected':
                    this.setState({ badgeColor: '#EEB31F'})
                    break;
                case 'infected':
                    this.setState({ badgeColor: '#EA3927'})
                    break;
                default:
                    this.setState({ badgeColor: '#EA3927'})
                    break;
            }
        }
        if(this.props.error && this.state.isLoading){
            this.setState({ isLoading: false});
            toast.error(<ToastMessage type='error' message='Error while verifying QR' />, { onClose: this.props.resetError})
        }
    }

    renderLoader(){
        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        {/* <Loader  type={loader} color="#1AB394" height={100} width={100} /> */}
                        <ScaleLoader color='#2768D4' height={100} width={10} radius={4} margin={4}/>
                    </Row>
                </Container>
            </div>
           
        )
    }

    render(){
        return (
            <React.Fragment>
                <DefaultLayout/>
                { this.state.isLoading ? this.renderLoader() :

                <div className="app flex-row align-items-center mt-n4">
                {this.props.verificationInfo && <Container className='mt-n4'>
                    <Card>
                        
                        <CardBody>
                            <div className='justify-content-center row mt-5'>
                                { this.props.verificationInfo.isVerified ? <i className='fa fa-check-square fa-5x' style={{ color:'#00B961'}} /> : 
                                    <i className='fa fa-window-close fa-5x' style={{ color:'#EA3927'}} />}
                            </div>
                            <div className='justify-content-center row mt-3'>
                                <p className= 'checkout-text' >{this.props.verificationInfo.isVerified ? 'Verified' : 'Not Verified'}</p>
                            </div>
                            
                            <div className="justify-content-center row mt-2">
                                <p className= 'checkout-time' >{moment(this.props.verificationInfo.lastCheckInDate).format('DD.MM.YYYY - hh:mm A')}</p>
                            </div>

                            <div className="justify-content-center row mt-5">
                                <Label style={styles.labelStyle}>Mobile Number</Label>
                            </div>
                            <div className="justify-content-center row mt-2">
                                <Label className='registered-number'>{this.props.verificationInfo.contactPhone}</Label>
                                
                            </div>
                            <div className="justify-content-center row mt-2">
                                <QRCode 
                                    value={this.props.verificationInfo.riskStatus} 
                                    size={91} 
                                    includeMargin={true} 
                                    fgColor={this.state.badgeColor}
                                    renderAs={'svg'} />
                            </div>
                        </CardBody>
                        
                    </Card>
                    
                    
                    
                </Container> }
            </div>}
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        verificationInfo:state.user.verificationInfo,
        error: state.user.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return { ...bindActionCreators({  verifyUserQR, resetError  }, dispatch) }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser)
