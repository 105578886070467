import React, { Component } from 'react';
import { Alert } from 'antd';
import NetworkManager from '../API/NetworkManager';
import { toast } from 'react-toastify';
import ToastMessage from './ToastMessage';

export default function (ComposedComponent) {
    class NetworkDetector extends Component {
      constructor(props){
          super(props);
          this.toastId = null;
          this.state = {
              isOffiline: false,
              
          }
      }

      componentDidMount() {
          this.handleConnectionChange();
          window.addEventListener('online', this.handleConnectionChange);
          window.addEventListener('offline', this.handleConnectionChange);
        }

        componentWillUnmount() {
          window.removeEventListener('online', this.handleConnectionChange);
          window.removeEventListener('offline', this.handleConnectionChange);
        }


        handleConnectionChange = () => {
          const condition = navigator.onLine ? 'online' : 'offline';
          if (condition === 'online') {
            const webPing = setInterval(
              () => {
                NetworkManager.pingBaseUrl()
                .then(() => {
                  this.setState({ isOffiline: false }, () => {
                    return clearInterval(webPing)
                  });
                  toast.dismiss(this.toastId);
                }).catch(() => this.setState({ isOffiline: true }) )
              }, 2000);
            return;
          }

          return this.setState({ isOffiline: true });
        }

      
      renderToast(){
        this.toastId = toast.error(<ToastMessage message='No Internet Connection' type='error' />, { autoClose: false, closeButton: false, containerId: 'networkError', toastId:'networkError'});
        
      }
      render() {
          const { isOffiline } = this.state;
          return (
            <div>
              { isOffiline && this.renderToast() }
              <ComposedComponent {...this.props} />
            </div>
          );
        }
    }
    return NetworkDetector;
    }

