import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

class Page404 extends Component {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container style={{ marginTop: '100px'}}>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <h1 className="float-left display-3 mr-4">404</h1>
                <h4 className="pt-3">Houston, we have a problem!</h4>
                <p className="text-muted float-left">The requested assessment is already submitted.</p>
                <p className="text-muted float-left">To make any changes to submitted assessment contact CAST team.</p>
              </span>
             
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page404;
