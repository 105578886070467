import NetworkManager from '../API/NetworkManager';
import { GET_ENTITY_SUCCESS, GET_ENTITY_ERROR } from './actions';


export const getEntityDetail = (entityId) => {
    return  (dispatch) => {
        
        NetworkManager.getEntityDetails(entityId).then(response => {
           dispatch(getEntitySuccess(response));
        }).catch(error=> {
           dispatch(getEntityError(error));
        });
    }
}


const getEntitySuccess = (payload) => {
    return { type: GET_ENTITY_SUCCESS, payload}
}

const getEntityError = (payload) => {
    return { type: GET_ENTITY_ERROR, payload }
}