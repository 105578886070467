import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { bindActionCreators } from 'redux';
import { Col, Button, Form, FormGroup, Label, Input, Row, Container, Card, CardBody, InputGroup, InputGroupAddon } from 'reactstrap';
import { verifyOtp, sendOtp, verifyToken, resetError } from '../../actions/loginAction';
import OtpInput from 'react-otp-input'
import LoadingOverlay from 'react-loading-overlay';
import  { ScaleLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import ToastMessage from '../../components/ToastMessage';
import MaskData from 'maskdata';
import LandingImage from '../../assets/landing.png'
import OTPPageImage from '../../assets/otp.png';
import withSizes from 'react-sizes';
import DefaultLayout from '../../components/DefaultLayout';
import storageService from '../../services/StorageService';

const styles = {
    labelStyle: {
        height: '16px',
        width: '97px',
        color: '#1F1F1F',
        fontSize: '14px',
        letterSpacing: 0,
        lineHeight: '16px',
        textAlign: 'center'
    },
    buttonStyle: {
        height: '57px',
        width: '265px',
        borderRadius: '36.5px',
        backgroundColor: '#2768D4',
        color:' #FFFFFF',
        fontSize: '24px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '29px'
    },
    inputStyle: {
        height: '3.25rem',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF'
    },
    countryInput: {
        boxSizing: 'border-box',
        height: '3.25rem',
        width: '81px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
        marginRight: '10px'
      }

}

class UserCheckIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            entityId: null,
            isMobileEmpty: false,
            isLoading: false,
            isAPICall: false,
            contactPhone: '',
            isOtpEmtpty:false,
            otp:'',
            showOtpBox:false,
            countryCode: '+65',
            isCountryCodeEmpty: false,
            minutes: 3,
            seconds: 0,
            userBlocked: false,
            verifyingOtp:false
        }
        this.onProceedButtonClick = this.onProceedButtonClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let { search } = this.props.location
        if (search) {
            let queryParam = qs.parse(search, { ignoreQueryPrefix: true });
            this.setState({ entityId: queryParam.entityId, isLoading: true });
            this.props.verifyToken();
        }


    }
    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    componentDidUpdate(){
        if(this.props.isUserLoggedIn && this.state.isLoading){
            this.setState({isLoading: false});
            this.props.history.replace(`/entity/checkIn/submit/${this.state.entityId}`);
        }
        if(this.props.tokenError && this.state.isLoading){
            this.setState({isLoading: false});
        }
        if(this.props.isOtpVerified && this.state.verifyingOtp){
            this.setState({verifyingOtp: false});
            storageService.removeItem('otp_retries');
            this.props.history.replace(`/entity/checkIn/submit/${this.state.entityId}`);
        }
        
        if(this.props.otpError && this.state.isAPICall){
            this.setState({isAPICall: false});
            toast.error(<ToastMessage type='error' message='OTP sending failed.' />, { onClose: this.props.resetError})
        }

        if(this.props.verifyOtpError && this.state.verifyingOtp){
            this.setState({verifyingOtp: false});
            toast.error(<ToastMessage type='error' message='OTP verification failed.' />, { onClose: this.props.resetError})
        }
        if(this.props.otpPayload && this.state.isAPICall){
            const noOfRetries = storageService.getItem('otp_retries');
            this.setState((state) => ({
                isAPICall: false, 
                showOtpBox: true, 
                minutes: noOfRetries<3 ? 3 : 30, 
                seconds:0
            }))
            this.startTimer()
        }
    }


    render() {
        const { minutes, seconds } = this.state
        const noOfRetries = storageService.getItem('otp_retries');
        return (
            <React.Fragment>
                <DefaultLayout/>
                <LoadingOverlay 
                            active={this.state.isLoading || this.state.isAPICall || this.state.verifyingOtp}
                            spinner={<ScaleLoader color='#03153E' height={100} width={10} radius={4} margin={4}/>}
                            styles={{
                                overlay: (base) => ({
                                ...base,
                                background: 'none'
                                })
                            }}
                            >
                    <div className="app flex-row align-items-center">
                        <Container>
                            <FormGroup>
                                <h1 className='h1Label'>{!this.state.showOtpBox ? 'Stop Spread' : 'Enter OTP'}</h1>
                            </FormGroup>
                        
                            <FormGroup className={this.props.isMobile ? 'mt-4 justify-content-center' : 'mt-4 justify-content-center row'}>
                                    {!this.state.showOtpBox ?<Label className='formLabel'>Enter your mobile number, we will send you OTP to verify later</Label> :
                                    <Label className='formLabel'>Please enter the OTP that was sent to your mobile number {MaskData.maskPhone(`${this.state.countryCode} ${this.state.contactPhone}`, { maskWith: '*', unmaskedStartDigits: 4, unmaskedEndDigits: 4})}.</Label>}
                            </FormGroup>
                            <Card>
                                <CardBody>
                                    <Row className="justify-content-center">
                                
                                        <Col xs="12" sm={10} lg={5} md={6}>
                                            {!this.state.showOtpBox && <Form>
                                                <div className="justify-content-center row">
                                                <Label style={styles.labelStyle}>Mobile Number</Label>
                                                </div>
                                                
                                                <FormGroup className='mt-2'>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <Input invalid={this.state.isCountryCodeEmpty}
                                                                maxLength={4}
                                                                style={styles.countryInput}
                                                                type='text'
                                                                name="countryCode"
                                                                id="countryCode"
                                                                autoComplete="off"
                                                                value={this.state.countryCode}
                                                                onChange={this.handleChange} />
                                                        </InputGroupAddon>
                                                        
                                                        <Input invalid={this.state.isMobileEmpty}
                                                            style= {styles.inputStyle}
                                                            type='number'
                                                            name="contactPhone"
                                                            id="contactPhone"
                                                            autoComplete="off"
                                                            value={this.state.contactPhone}
                                                            onChange={this.handleChange} />
                                                    </InputGroup>         
                                                </FormGroup>
                                                <FormGroup className="justify-content-center" row style={{ marginTop: '55px' }}>
                                                    <Button style={styles.buttonStyle} type='submit' onClick={this.sendOtp}>Continue</Button>
                                                </FormGroup>
                                            
                                            </Form>}

                {this.state.showOtpBox &&   <div>
                                                <Form>
                                                    <FormGroup style={{marginRight: '10px'}}>
                                                        <OtpInput
                                                            inputStyle="otpInputStyle"
                                                            numInputs={6}
                                                            value={this.state.otp}
                                                            errorStyle="error"
                                                            onChange={this.handleOtpChange}
                                                            shouldAutoFocus
                                                            hasErrored={this.state.isOtpEmtpty}
                                                            />
                                                    </FormGroup>
                                                
                                                    <FormGroup className="justify-content-center" row style={{ marginTop: '55px' }}>
                                                        <Button type='submit'onClick={this.onProceedButtonClick} style={styles.buttonStyle}>Continue</Button>
                                                    </FormGroup>
                                                </Form>
                                            
                                            </div>}
                                        </Col>
                                    </Row>
                                {this.state.showOtpBox && <Row>
                                                    <Col xs={3}>
                                                        <Button color='link' className='backBtn' onClick={() => this.setState({showOtpBox: false})}>Back</Button>
                                                    </Col>
                                {this.props.otpPayload && this.props.otpPayload.isResendOTP && 
                                                    <Col xs={9}>
                                                    { minutes === 0 && seconds === 0 && noOfRetries <3 ?   
                                                        <Button color='link' className='backBtn float-right' onClick={this.resendButtonClick}>Resend OTP</Button> 
                                                        :
                                                        <label className='float-right mt-2'>Resend OTP: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</label>}
                                                    </Col>}
                                                </Row>}
                                </CardBody>
                            </Card>
                            
                            {!this.state.showOtpBox && <div className="justify-content-center row">
                                <p className='text'>Community-driven contact tracing!</p>
                            </div>}
                            <div className='container mt-4 px-0'>
                                <img src={!this.state.showOtpBox ? LandingImage: OTPPageImage} width='100%' alt='Background'/>
                            </div>   
                        </Container>
                    </div>
                </LoadingOverlay>
            </React.Fragment>
            
        )
    }

    onProceedButtonClick = (event) => {
        event.preventDefault();
        if(this.state.otp.toString().length !== 6){
            this.setState({ isOtpEmtpty: true});
            return;
        }
       
        const data = {
            mobileNumber: `${this.state.countryCode}${this.state.contactPhone}`,
            otp: this.state.otp.toString(),
            token: this.props.otpPayload.token
        }
        this.setState({ verifyingOtp: true });
        this.props.verifyOtp(data);
    }

    sendOtp = (event) => {
        event.preventDefault();
        clearInterval(this.myInterval)
        let noOfRetries = storageService.getItem('otp_retries');
        if(!noOfRetries)
            storageService.setItem('otp_retries', 0);

        if(this.state.contactPhone === ''){
            this.setState({ isMobileEmpty: true})
            return;
        }
        if(this.state.countryCode === ''){
            this.setState({ isCountryCodeEmpty: true})
            return;
        }
        this.setState({ isAPICall: true });
        const data = {
            mobileNumber: `${this.state.countryCode}${this.state.contactPhone}`
        }
        this.props.sendOtp(data);
    }

    handleChange = event => {
        if (event.target.id === 'contactPhone') {
            this.setState({ isMobileEmpty: false, contactPhone: event.target.value })
        }

        if (event.target.id === 'countryCode') {
            this.setState({ isCountryCodeEmpty: false, countryCode: event.target.value })
        }
    };

    handleOtpChange = (otp) => {
        this.setState({ otp: otp})
    }

    startTimer = () => {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                //this.setState({ seconds: this.state.seconds -1})
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                    if(this.state.userBlocked){
                        storageService.setItem('otp_retries', 0)
                        this.setState(({ userBlocked}) => ({
                            userBlocked: true
                        }))
                    }
                       
                } else {
                    //this.setState({ minutes: this.state.minutes -1 , seconds:59})
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    resendButtonClick = () => {
        clearInterval(this.myInterval);
        let noOfRetries = storageService.getItem('otp_retries');
        noOfRetries = noOfRetries+1;
        storageService.setItem('otp_retries', noOfRetries);
        
        if(noOfRetries <=3){
            if(noOfRetries === 3 ){
                this.setState({ minutes: 30, seconds: 0, isAPICall: true, userBlocked: true});
            }else {
                this.setState({ minutes: 3, seconds: 0, isAPICall: true});
            }
           
            const data = {
                mobileNumber: `${this.state.countryCode}${this.state.contactPhone}`
            }
            this.props.sendOtp(data);
           
        }
        
    }

}
const mapStateToProps = (state) => {
    return {
        isOtpVerified:state.login.isOtpVerified,
        otpError: state.login.otpError,
        otpPayload: state.login.otpPayload,
        isUserLoggedIn: state.login.isUserLoggedIn,
        tokenError: state.login.tokenError,
        verifyOtpError: state.login.verifyOtpError
    }

}

const mapDispatchToProps = (dispatch) => {
    return { ...bindActionCreators({  verifyOtp, sendOtp, verifyToken, resetError  }, dispatch) }
}

const mapSizesToProps = (sizes) => ({
    windowWidth: sizes.width,
    isMobile: sizes.width< 768
})

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(UserCheckIn))