import aes from 'crypto-js/aes'
import encUtf8 from 'crypto-js/enc-utf8'

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY

class StorageService {

    setEncryptedItem(key, value){
        let encrypted = aes.encrypt(JSON.stringify(value), encryptionKey);
        localStorage.setItem(key, JSON.stringify(encrypted.toString()));
    }

    getEncryptedItem(key) {
        const value = localStorage.getItem(key);
        if(value){
            let decrypted = aes.decrypt(JSON.parse(value),encryptionKey).toString(encUtf8);
            return JSON.parse(decrypted);
        }
       
    }

    setItem(key, value){
        localStorage.setItem(key, JSON.stringify(value));
    }

    getItem(key) {
        const value = localStorage.getItem(key);
        console.log(' value is ', typeof value)
        if(value){
            return JSON.parse(value);
        }
       
    }

    removeItem(key) {
        localStorage.removeItem(key);
    }
}

export default new StorageService();