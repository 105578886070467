import React, { Component } from 'react';
import { Col, Container, Row} from 'reactstrap';
import BackgroundImage from '../../assets/logout.png';
import DefaultLayout from '../../components/DefaultLayout';

class Logout extends Component {

    constructor(props){
        super(props)
        this.state = {
            showQRReader:false,
            qrUrl:''
        }
    }

    render(){

        return (
            <React.Fragment>
                <DefaultLayout/>
                <div className="app flex-row align-items-center" style={{ marginTop: '-5rem'}} >
                <Container className='mt-n5'>
                    <Row className="justify-content-center">
                        <Col lg="6" xs="12" sm={10}>
                            <img src={BackgroundImage} style={{width: '100%'}} alt='Background' /> 
                            
                        </Col>
                    
                    
                            <div className='justify-content-center row mt-4'>
                                <p className= 'success-text' >Please scan the establishment’s QR code or input the provided URL in your browser to login</p>
                                <p>{this.state.qrUrl}</p>
                            {/* <Label style={styles.formLabel} className='mt-4'>Please scan QR code to login to your account!</Label> */}
                            </div>
                            
                            {/* <div className="justify-content-center row mt-5">
                                    <Button style={styles.buttonStyle} type='submit' onClick={this.openCamerButtonClick}>Open Camera</Button>
                            </div> */}
                    
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        )
    }

    /*openCamerButtonClick = () => {
        console.log('This props history is ', this.props);
        this.setState({ showQRReader : true})
    }

    handleScan = data => {
        if (data) {
          this.setState({ qrUrl: data})
          this.props.history.replace(data);
        }
      }
      handleError = err => {
        console.error(err)
    }*/
}

export default Logout;